@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Raleway:wght@200&family=Russo+One&family=Varela+Round&display=swap');

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Patua One', cursive;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, rgba(169, 142, 212, 0.37), rgba(89, 119, 202, 0.616));
}

@keyframes confirm-delete {
  from {
    transform: translateY(-250px);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes collections {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes shrink-animation {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@keyframes before-update {
  0% {
    transform: translateX(50vw);
  }

  100% {
    transform: translateX(5vw);
  }
}

@keyframes after-update {
  0% {
    transform: translateX(5vw);
  }

  100% {
    transform: translateX(50vw);
  }
}

@keyframes new-collection-in {
  from {
    transform: translateY(-400px);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes new-collection-out {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-400px);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*Header*/
#page-header {
  width: 100%;
  position: fixed;
  top: 0px;
  height: 75px;
  background-color: rgb(95, 170, 95);
  align-content: center;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 5px 5px 5px 5px rgba(107, 99, 99, 0.603);
  z-index: 10;
}

#header-container {
  display: flex;
  place-content: center;
  justify-content: space-between;
}

.header-option {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  vertical-align: top;
}

#favorites {
  min-width: 900px;
}

#favorites img {
  margin-right: 20px;
}

#favorites button {
  color: rgb(236, 227, 227);
  font-size: 22px;
  margin-top: 5px;
  margin-left: 30px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  transition: 0.3s;
}

#favorites button:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

#flag-icon {
  width: 30px;
  height: 20px;
  margin-left: 800px;
  margin-top: -30px;
  display: flex;
  cursor: pointer;
}

#flag-icon img {
  width: 30px;
  height: 20px;
}

#flag-icon p {
  margin-top: 0px;
  margin-left: -10px;
}

#flag-choice {
  position: absolute;
  width: 120px;
  top: 48px;
  margin-left: 780px;
  border: 1px solid;
  background-color: rgba(255, 255, 255, 0.945);
  padding: 10px;
  border-radius: 5px;
}

#flag-choice div {
  display: flex;
  margin-top: 5px;
  cursor: pointer;
}

#flag-choice div p {
  margin-top: 0px;
  margin-left: 5px;
}

.flag-option {
  width: 30px;
  height: 20px;
}

#search-account {
  place-self: right;
  min-width: 200px;
  display: flex;
  margin-right: 0px;
}

#login {
  color: rgb(236, 227, 227);
  margin-left: 40px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  transition: 0.3s;
  margin-right: -100px;
}

#login-es {
  color: rgb(236, 227, 227);
  margin-left: 40px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  transition: 0.3s;
  margin-right: -100px;
}

#login:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

#user-icon {
  width: 50px;
  margin-left: 50px;
  margin-top: -5px;
  cursor: pointer;
}

.header-option-icon {
  border: 0px;
  background-color: transparent;
  cursor: pointer;
}

#user-options {
  display: none;
  position: absolute;
  top: 70px;
  border: 1px solid;
  width: 120px;
  margin-left: 40px;
  margin-top: 0px;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 5px;
}

#user-options button {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: 0.1s;
  font-family: 'Patua One', cursive;
}

#user-options button:hover {
  transform: scale(1.1);
  transition: 0.1s;
}

#display-element {
  width: 150px;
}

/*Welcome Page*/
#welcome {
  display: grid;
  margin-top: 75px;
  place-items: center;
  width: 100%;
}

#welcome-title {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.671), rgba(249, 250, 250, 0.315));
  background-color: rgba(3, 36, 223, 0.452);
  width: 100%;
  /* width: 100%; */
  height: 100vh;
  display: grid;
  place-items: center;
  box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.171);
}

#main-title {
  font-size: 150px;
  margin-top: -50px;
  margin-bottom: -50px;
  font-weight: 500;
  color: rgba(30, 211, 105, 0.74);
  -webkit-background-clip: text;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
  text-shadow: 5px 5px 5px rgba(117, 25, 25, 0.603);
  transition: 0.3s;
  cursor: pointer;
  white-space: wrap;
  overflow-wrap: break-word;
  text-align: center;
}

#main-title:hover {
  transform: scale(1.01);
  transition: 0.3s;
}

#icon {
  width: 350px;
  border: 5px black;
  border-radius: 500px;
  box-shadow: 15px 15px 15px rgba(124, 65, 65, 0.171);
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s;
  filter: sepia(20%);
  /*mix-blend-mode:darken;*/
}

#icon:hover {
  transform: scale(1.03);
  transition: 0.3s;
}

#intro {
  font-size: 30px;
  color: rgb(36, 37, 37);
  margin-top: -20px;
  font-weight: 900;
}

#main-welcome {
  width: 100%;
}

#welcome-search {
  background: linear-gradient(90deg, rgba(219, 175, 243, 0.315), rgba(102, 101, 209, 0.459));
  width: 100%;
  height: 100vh;
  display: grid;
  box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.11);
  place-content: center;
  place-items: center;
}

#search-title {
  font-size: 75px;
  margin-top: -50px;
  margin-bottom: 100px;
}

#scroll-container {
  position: absolute;
  display: flex;
  width: 100%;
  gap: 100%;
  left: -20px;
  margin-top: 130px;
}

#scroll-left {
  height: 500px;
  width: 100px;
  font-size: 200px;
  padding-top: 120px;
  margin-left: 20px;
  margin-right: -130px;
  color: #dadada;
  z-index: 1;
  cursor: pointer;
  /* background-color: #63636367; */
  background-color: #63636300;
  opacity: 50%;
  font-family: monospace
}

#scroll-left:hover {
  opacity: 100%;
}

#scroll-left img {
  width: 100px;
  margin-top: 50px;
}

#scroll-right {
  height: 500px;
  width: 100px;
  font-size: 200px;
  padding-top: 120px;
  margin-left: -70px;
  margin-right: 0px;
  color: #a8a8a8;
  z-index: 1;
  cursor: pointer;
  /* background-color: #63636367; */
  background-color: #63636300;
  opacity: 50%;
  font-family: monospace
}

#scroll-right:hover {
  opacity: 100%;
}

#scroll-right img {
  width: 100px;
  margin-top: 50px;
}

#welcome-option-container {
  display: flex;
  margin-left: 0px;
  width: 100vw;
  height: 510px;
  position: relative;
  /* position: absolute;
  left: 0; */
  overflow-x: hidden;
}

#welcome-option-container::-webkit-scrollbar {
  display: none;
}

.welcome-option {
  width: 300px;
  min-width: 300px;
  height: 450px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.151);
  cursor: pointer;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.151);
}

.welcome-option img {
  width: 300px;
  height: 300px;
  border-radius: 5px;
}

.option-label {
  font-size: 30px;
  text-align: center;
  color: rgb(24, 49, 98);
  max-width: 300px;
  max-height: 120px;
  overflow: auto;
}

.option-label::-webkit-scrollbar {
  display: none;
}

#welcome-share {
  background: linear-gradient(90deg, rgba(186, 148, 207, 0.315), rgba(66, 65, 139, 0.459));
  height: 100vh;
  box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.171);
  display: grid;
  place-content: center;
  place-items: center;
}

#welcome-share-prompt {
  font-size: 50px;
  margin-top: 0px;
  max-width: 1000px;
  text-align: center;
}

#welcome-share-image {
  width: auto;
  height: 500px;
  border: 1px white;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

#welcome-share-image img {
  border-radius: 10px;
  height: 500px;
  margin-bottom: 50px;
}

#welcome-share-button {
  cursor: pointer;
  width: 250px;
  height: 70px;
  background-color: rgba(91, 48, 160, 0.548);
  border-radius: 10px;
  font-size: 25px;
  font-family: 'Patua One', cursive;
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#welcome-share-button:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#welcome-comunity {
  background: linear-gradient(90deg, rgba(219, 190, 235, 0.315), rgba(121, 95, 155, 0.459));
  height: 100vh;
  box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.171);
  display: grid;
  place-content: center;
  place-items: center;
}

#welcome-community-prompt {
  font-size: 40px;
  margin-top: -50px;
  max-width: 800px;
  text-align: center;
  margin-bottom: 70px;
}

#welcome-community-image-container {
  width: 1000px;
  display: grid;
  place-content: center;
}

#welcome-community-image {
  width: 300px;
  height: 400px;
  margin-left: -100px;
  margin-bottom: 40px;
}

#welcome-community-image img {
  border-radius: 10px;
  width: 400px;
  box-shadow: 15px 15px 15px rgba(124, 65, 65, 0.493);
}

#welcome-community-button {
  cursor: pointer;
  width: 180px;
  height: 70px;
  background-color: rgba(32, 160, 81, 0.548);
  border-radius: 10px;
  font-size: 25px;
  font-family: 'Patua One', cursive;
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#welcome-community-button:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#welcome-store {
  background: linear-gradient(90deg, rgba(171, 122, 197, 0.315), rgba(105, 64, 158, 0.459));
  height: 100vh;
  box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.171);
  display: grid;
  place-content: center;
  place-items: center;
}

#welcome-store-prompt {
  font-size: 30px;
  margin-top: 0px;
  max-width: 700px;
  text-align: center;
}

#welcome-store-image {
  width: 300px;
  height: 400px;
  border: 1px white;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.26);
  margin-bottom: 40px;
}

#welcome-store-button {
  cursor: pointer;
  width: 250px;
  height: 70px;
  background-color: rgba(196, 221, 52, 0.548);
  border-radius: 10px;
  font-size: 25px;
  font-family: 'Patua One', cursive;
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#welcome-store-button:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

/*Footer*/
#page-footer {
  display: flex;
  gap: 20px;
  place-items: center;
  justify-content: end;
  padding-right: 50px;
  height: 50px;
  position: relative;
  font-family: Raleway, sans-serif;
  font-weight: 900;
}

#contact-container {
  border: 1px solid;
  background-color: rgba(255, 255, 255, 0.932);
  border-radius: 10px;
  place-content: center;
  width: 350px;
  height: 150px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  right: 50px;
  bottom: 50px;
}

.repository-link {
  text-decoration: none;
  color: black;
}

.contact-point {
  text-decoration: none;
}

#contact-info {
  cursor: pointer;
}

.link-img {
  width: 15px;
  height: 15px;
}

/*Login page*/
#login-container {
  width: 100vw;
  height: 200%;
  min-height: 91vh;
  margin-top: 150px;
  display: grid;
  place-content: center;
}

#login-title {
  font-size: 35px;
  text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  margin-top: -100px;
  margin-left: 150px;
}

#login-title-es {
  font-size: 35px;
  text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  margin-top: -100px;
  margin-left: 70px;
}

#login-form {
  background-color: rgba(37, 120, 192, 0.13);
  display: grid;
  gap: 30px;
  width: 430px;
  padding: 50px;
  border-radius: 16px;
  box-shadow: 10px 10px 10px rgba(124, 65, 101, 0.616);
}

#login-form label {
  font-size: 25px;
}

.form-input {
  width: 325px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid;
}

#forgot-pwd {
  place-self: center;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  margin-top: -10px;
  margin-bottom: -5px;
}

.form-persist-es {
  margin-left: -30px;
}

#persist {
  margin-right: 20px;
  margin-left: 50px;
}

#form-submit-button {
  background-color: rgba(40, 185, 137, 0.678);
  width: 200px;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-left: 65px;
  border: 1px solid;
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
}

#create-account-container {
  margin-left: 60px;
  max-width: 200px;
  text-align: center;
}

#back-container {
  font-size: 25px;
  margin-left: 140px;
  margin-top: 50px;
}

#verify-account-container {
  width: 100%;
  height: 100%;
  position: fixed;
  place-content: center;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.363);
}

#verify-username-container {
  width: 500px;
  height: 300px;
  padding: 20px;
  background-color: rgb(192, 229, 233);
  border-radius: 10px;
  place-content: center;
  box-shadow: 50px 50px 50px rgba(43, 32, 38, 0.616);
}

#verify-username-container p {
  font-size: 20px;
  margin-top: -10px;
}

#verify-username {
  margin-bottom: 20px;
}

#verify-buttons-container {
  display: flex;
  gap: 30px;
  width: 300px;
  margin-top: 30px;
  place-self: center;
}

#cancel-verify {
  width: 150px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  background-color: rgba(128, 128, 128, 0.918);
}

#verify-submit {
  width: 150px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  background-color: rgba(110, 96, 235, 0.808);
}

#new-pwd-container {
  width: 500px;
  height: 300px;
  background-color: rgb(134, 204, 235);
  border-radius: 10px;
  padding: 40px;
  place-content: center;
  box-shadow: 50px 50px 50px rgba(43, 32, 38, 0.616);
}

#new-pwd-container p {
  font-size: 20px;
}

#new-pwd {
  margin-bottom: 30px;
}

#confirm-new-pwd {
  margin-bottom: 30px;
}

.lds-dual-ring {
  display: inline-block;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.082);
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

#require-username-container {
  width: 100%;
  height: 100%;
  position: fixed;
  place-content: center;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.363);
}

#require-username {
  width: 300px;
  height: 150px;
  background-color: rgb(211, 211, 211);
  border-radius: 10px;
  padding-left: 70px;
  padding-top: 30px;
  box-shadow: 30px 30px 30px rgba(43, 32, 38, 0.616);
}

#require-username p {
  font-size: 18px;
}

#require-username button {
  width: 120px;
  height: 25px;
  place-self: center;
  margin-left: 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(110, 96, 235, 0.808);
}

#password-mismatch-container {
  width: 100%;
  height: 100%;
  position: fixed;
  place-content: center;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.363);
}

#password-mismatch {
  width: 320px;
  height: 150px;
  background-color: rgb(211, 211, 211);
  border-radius: 10px;
  padding-left: 70px;
  padding-top: 30px;
}

#password-mismatch p {
  font-size: 18px;
  width: 350px;
  justify-self: center;
  align-self: center;
  place-self: center;
  text-align: middle;
  margin-left: -35px;
}

#password-mismatch button {
  width: 120px;
  height: 25px;
  place-self: center;
  margin-left: 30px;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(110, 96, 235, 0.808);
}

#password-success-container {
  width: 100%;
  height: 100%;
  position: fixed;
  place-content: center;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.363);
}

#password-success {
  width: 300px;
  height: 150px;
  background-color: rgb(211, 211, 211);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  line-height: 25px;
  text-align: center;
  box-shadow: 30px 30px 30px rgba(43, 32, 38, 0.616);
}

#password-success p {
  font-size: 18px;
}

#password-success button {
  width: 120px;
  height: 25px;
  place-self: center;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(110, 96, 235, 0.808);
}

#unverified-container {
  width: 500px;
  height: 300px;
  border-radius: 10px;
  background-color: rgb(205, 241, 228);
  padding: 50px;
  box-shadow: 50px 50px 50px rgba(43, 32, 38, 0.616);
}

#unverified-container p {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
}

#unverified-container select {
  width: 250px;
  font-size: 15px;
  height: 30px;
  margin-top: 0px;
}

#unverified-buttons {
  width: 500px;
  display: flex;
  gap: 50px;
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: -20px;
}

#unverified-buttons button {
  width: 120px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
}

#verify-recipe {
  background-color: rgba(131, 87, 233, 0.822);
}

#failed {
  width: 500px;
  height: 200px;
  background-color: rgb(184, 241, 245);
  border-radius: 10px;
  padding-left: 50px;
  padding-top: 30px;
  box-shadow: 50px 50px 50px rgba(43, 32, 38, 0.616);
}

#failed p {
  font-size: 20px;
  margin-bottom: -20px;
}

#failed a {
  font-size: 20px;
}

/*New Recipe Page*/
#new-recipe-container {
  margin-top: 75px;
  display: grid;
  place-content: center;
}

#new-recipe-title {
  font-size: 50px;
  margin-left: 350px;
  margin-top: 30px;
  text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
}

#new-recipe-form {
  display: grid;
  gap: 20px;
  width: 1000px;
  border-radius: 16px;
  padding: 50px;
  background-color: rgba(23, 70, 54, 0.151);
  box-shadow: 20px 20px 20px rgba(124, 65, 101, 0.616);
  margin-bottom: 50px;
  margin-top: -30px;
}

.new-recipe-form-input {
  font-size: 25px;
  margin-top: 10px;
}

#cooking-time-es {
  margin-top: -10px;
  margin-left: 30px;
  margin-right: -60px;
}

#porciones {
  margin-left: 20px;
}

#new-ingredients-container {
  display: grid;
  gap: 10px;
}

#new-recipe-name {
  height: 45px;
  width: 900px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-weight: 900;
  font-size: 25px;
  color: rgb(0, 0, 0);
}

#category-time-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

#category-container {
  display: flex;
  gap: 10px;
}

#new-recipe-category {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-weight: 700;
  font-size: 18px;
  margin-top: 5px;
}

#servings-container {
  display: flex;
  gap: 10px;
}

#cooking-time-container {
  display: flex;
  gap: 10px;
}

#cooking-time {
  width: 150px;
  height: 30px;
  margin-top: 10px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 15px;
}

#cooking-time-input-es {
  width: 150px;
  height: 30px;
  margin-top: 10px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 15px;
}

#servings {
  width: 150px;
  height: 30px;
  margin-top: 10px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 15px;
}


.ingredient-container {
  width: 900px;
  display: flex;
  margin-left: -100px;
  gap: 10px;
}

.new-recipe-ingredient,
.quantity,
.ingredient-unit {
  border-radius: 5px;
}

.exclude-ingredient {
  width: 30px;
  height: 30px;
  margin-top: -3px;
  margin-bottom: -5px;
  margin-right: -30px;
  font-size: 25px;
  cursor: pointer;
  color: rgba(255, 0, 0, 0.671);
}

.new-recipe-ingredient {
  margin-left: 100px;
  font-size: 20px;
  width: 800px;
}

.quantity {
  width: 100px;
}

#add-ingredient {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  background-color: rgba(9, 30, 224, 0.39);
}

#new-recipe-preparation-style {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 18px;
}

#step-by-step-container {
  display: grid;
  gap: 30px;
}

.step-container {
  width: 900px;
  display: flex;
  gap: 5px;
}

.step-container label {
  width: 100px;
  font-size: 20px;
}

.step {
  width: 850px;
  height: 150px;
  border-radius: 5px;
  resize: none;
  font-family: 'Patua One', cursive;
  font-size: 20px;
}

.exclude-step {
  width: 30px;
  height: 30px;
  margin-top: 50px;
  margin-bottom: -5px;
  margin-right: -30px;
  font-size: 25px;
  cursor: pointer;
  color: rgba(255, 0, 0, 0.671);
}

#add-step {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  background-color: rgba(9, 31, 224, 0.39);
}

#hidden {
  font-weight: 100;
  color: rgb(94, 99, 104);
}

#new-recipe-ingredients {
  height: 200px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 18px;
  resize: none;
}

#new-recipe-preparation {
  height: 300px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 18px;
  resize: none;
}

#pictures-container {
  width: 100%;
  display: flex;
}

#pictures-input {
  display: grid;
  gap: 20px;
}

#pictures-preview {
  display: flex;
  gap: 10px;
}

#new-recipe-pictures {
  width: 200px;
  margin-bottom: 50px;
}

.image-thumbnail {
  width: 120px;
  height: 150px;
}

.image-thumbnail img {
  width: 120px;
  height: 140px;
  z-index: -1;
}

.image-thumbnail p {
  color: red;
  margin-top: -25px;
  font-size: 25px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 900;
  text-align: right;
  padding-right: 10px;
  cursor: pointer;
  filter: opacity(80%);
  background-color: rgb(255, 255, 255);
}

#new-recipe-buttons {
  display: flex;
  gap: 30px;
  margin-left: 230px;
}

#edit-recipe-buttons {
  display: flex;
  gap: 30px;
  margin-left: 120px;
}

#new-recipe-submit {
  width: 220px;
  height: 50px;
  border: 2px solid;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 25px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(92, 212, 22, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#new-recipe-submit:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#post-recipe-submit {
  width: 220px;
  height: 50px;
  border: 2px solid;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 25px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(92, 212, 22, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#post-recipe-submit:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#new-recipe-cancel {
  border: 2px solid;
  padding-top: 8px;
  padding-left: 70px;
  width: 220px;
  height: 50px;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 25px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(92, 92, 92, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#new-recipe-cancel:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#post-recipe-cancel {
  border: 2px solid;
  padding-top: 8px;
  padding-left: 70px;
  width: 220px;
  height: 50px;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 25px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(92, 92, 92, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#post-recipe-cancel:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#new-recipe-cancel-es {
  border: 2px solid;
  padding-top: 8px;
  padding-left: 50px;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 25px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(92, 92, 92, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#new-recipe-cancel-es:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#post-recipe-cancel-es {
  border: 2px solid;
  padding-top: 8px;
  padding-left: 50px;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 25px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(92, 92, 92, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#post-recipe-cancel-es:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#new-recipe-delete {
  border: 2px solid;
  padding-top: 8px;
  padding-left: 35px;
  width: 220px;
  height: 50px;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 25px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(92, 92, 92, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#new-recipe-delete:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#new-recipe-delete-es {
  border: 2px solid;
  padding-top: 8px;
  padding-left: 20px;
  width: 220px;
  height: 50px;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 25px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(92, 92, 92, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#new-recipe-delete-es:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#missing-recipe-data {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  justify-content: center;
  place-content: center;
}

#missing-data-container {
  padding: 50px;
  border-radius: 10px;
  background-color: rgba(219, 207, 207, 0.973);
  box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
  animation: confirm-delete 0.15s linear 1;
  text-align: center;
  z-index: 2;
}

#missing-data-container button {
  background-color: rgba(5, 5, 212, 0.63);
}

#missing-message {
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: -20px;
}

#ok-missing {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
}

#edit-recipe-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.432);
  place-content: center;
  border-radius: 10px;
}

/*New User Page*/
#new-user-container {
  margin-top: 75px;
  display: grid;
  place-content: center;
}

#new-user-title {
  font-size: 50px;
  margin-left: 150px;
  margin-top: 30px;
  text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
}

#new-user-form {
  display: grid;
  gap: 20px;
  width: 700px;
  border-radius: 16px;
  padding: 50px;
  background-color: rgba(31, 94, 73, 0.171);
  box-shadow: 15px 15px 15px rgba(124, 65, 101, 0.616);
  margin-bottom: 50px;
  margin-top: -30px;
}

.new-user-form-input {
  font-size: 25px;
}

.new-user-form-value {
  height: 35px;
  width: 400px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-weight: 900;
  font-size: 20px;
  color: rgb(0, 0, 0);
}

#countries {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-weight: 700;
  font-size: 18px;
}

#about {
  height: 150px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 18px;
  resize: none;
}

#avatar-prompt {
  font-size: 25px;
  margin-bottom: 30px;
}

#avatars-imgs-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 700px;
  height: fit-content;
}

.signup-avatar {
  width: 90px;
  height: 90px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 500px;
}

.signup-avatar:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

.selected-avatar {
  border-radius: 500px;
  border: 5px solid red;
}

#new-user-submit {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  font-family: 'Patua One', cursive;
  font-size: 20px;
  place-self: center;
  cursor: pointer;
  margin-top: 30px;
  background-color: rgba(22, 98, 212, 0.521);
  box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  transition: 0.2s;
}

#new-user-submit:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#login-option-container {
  margin-left: 190px;
}

#missing-login-data {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  justify-content: center;
  place-content: center;
}

#missing-login-container {
  padding: 50px;
  border-radius: 10px;
  background-color: rgba(219, 207, 207, 0.973);
  box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
  animation: confirm-delete 0.15s linear 1;
  text-align: center;
  z-index: 2;
}

#missing-login-container button {
  background-color: rgba(5, 5, 212, 0.63);
}

#missing-login {
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: -20px;
}

#ok-login {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
}

#signup-loading {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  margin-top: 0px;
  margin-top: 0px;
  background-color: rgba(0, 0, 0, 0.312);
  padding-top: 25%;
  padding-left: 40%;
}

.signup-lds-dual-ring {
  display: inline-block;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
}

.signup-lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

/*Recipe Page*/
#recipe-page-container {
  margin-top: 75px;
  display: grid;
  place-content: center;
}

#name-edit {
  display: flex;
  gap: 20px;
  place-self: center;
}

#recipe-title {
  font-size: 45px;
  place-self: center;
  text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  max-width: 800px;
  text-align: center;
}

#pencil-icon {
  width: 30px;
  height: 30px;
  margin-top: 40px;
  cursor: pointer;
  filter: opacity(70%);
}

#recipe-container {
  width: 1000px;
  border-radius: 20px;
  display: grid;
  place-items: left;
  margin-top: 50px;
  background: linear-gradient(180deg, rgba(179, 132, 132, 0.151), rgba(3, 85, 74, 0.288));
  box-shadow: 25px 25px 25px rgba(124, 65, 101, 0.616);
}

#recipe-category {
  font-size: 25px;
  place-self: center;
  margin-top: -20px;
  margin-bottom: 50px;
  font-weight: 900;
}

#thumbnails-container {
  width: 800px;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-left: 100px;
  margin-bottom: 50px;
}

.recipe-thumbnail {
  width: 300px;
  margin-top: 50px;
  margin-left: 50px;
}

.recipe-thumbnail img {
  width: 300px;
  border-radius: 10px;
  max-height: 350px;
}

.recipe-label {
  font-size: 30px;
  margin-left: 70px;
  text-shadow: 3px 3px 3px rgba(124, 65, 101, 0.616);
  margin-top: 5px;
  margin-bottom: 35px;
}

#recipe-servings {
  display: flex;
  margin-top: -200px;
}

#time-servings-picture {
  display: flex;
  width: 900px;
  margin-top: -50px;
  place-self: center;
}

#time-servings {
  gap: 200px;
  display: grid;
  width: 700px;
  place-content: center;
  margin-top: 50px;
  margin-left: -80px;
}

#serving-label {
  font-size: 25px;
  margin-top: 8px;
  margin-left: 15px;
}

#recipe-cooking-time {
  display: flex;
  gap: 30px;
  margin-bottom: -10px;
}

#cooking-time-label {
  font-size: 25px;
  margin-top: 10px;
  margin-left: -10px;
}

#cooking-time-label-es {
  font-size: 25px;
  margin-top: 40px;
  margin-left: -50px;
  width: 200px;
}

#ingredient-units-type {
  width: 150px;
  height: 30px;
  margin-left: 250px;
  margin-top: -65px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 15px;
}

#ingredients-container {
  display: grid;
  margin-left: 80px;
  grid-template-columns: 440px 440px;
  gap: -5px;
  max-width: 900px;
  white-space: wrap;
  overflow-x: inherit;
  line-height: 25px;
  line-break: unset;

}

.ingredient {
  margin-left: 0px;
  font-size: 18px;
  max-width: 420px;
  margin-top: -20px;
  margin-right: -300px;
}

#instructions {
  margin-left: 100px;
  font-size: 20px;
  width: 800px;
  margin-top: -30px;
}

#instructions div p {
  margin-top: 12px;
  margin-bottom: 0px;
}

#author {
  place-self: end;
  margin-right: 50px;
}

#created-on {
  margin-left: 30px;
}

#likes-comments-share {
  display: flex;
  gap: 20px;
  margin-left: auto;
  margin-top: 15px;
  margin-right: 10px;
}

#like-container {
  display: flex;
}

#like-icon {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.2s;
}

#like-icon:hover {
  transform: scale(1.2);
  transition: 0.2s;
}

#star-icon {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.2s;
}

#star-icon:hover {
  transform: scale(1.2);
  transition: 0.2s;
}

#plus-icon {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.2s;
}

#plus-icon:hover {
  transform: scale(1.2);
  transition: 0.2s;
}

#share-icon {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.2s;
}

#share-icon:hover {
  transform: scale(1.2);
  transition: 0.2s;
}

#share-options-container {
  width: 150px;
  height: 270px;
  background-color: rgba(255, 255, 255, 0.952);
  border-radius: 5px;
  position: absolute;
  margin-top: 40px;
  margin-left: 100px;
  padding: 10px;
  border: 1px solid;
}

.share-option {
  display: flex;
  gap: 5px;
  cursor: pointer;
  width: fit-content;
  transition: 0.1s;
}

.share-option:hover {
  transform: scale(1.03);
  transition: 0.1s;
}

.share-icon {
  width: 30px;
  height: 30px;
}

.share-label {
  font-size: 15px;
  margin-top: 8px;
}

#coments-container {
  display: grid;
  gap: 20px;
  margin-bottom: 50px;
  margin-left: 100px;
}

#all-comments {
  margin-top: 50px;
  background-color: rgba(255, 255, 255, 0.24);
  width: 900px;
  padding: 50px;
  border-radius: 20px;
}

#show-more-button {
  width: 300px;
  margin-left: -30px;
  background-color: transparent;
  border: 0;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.comment-container {
  width: 800px;
  border-bottom: 1px solid;
  display: grid;
  margin-bottom: 50px;
  padding: 20px;
}

.comment-user-content {
  display: flex;
  width: 750px;
}

.comment-image-content {
  display: flex;
  width: 1200px;
}

.comment-user {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  cursor: pointer;
}

.comment-content {
  font-size: 20px;
  overflow-wrap: break-word;
  max-width: 500px;
}

.comment-date-delete {
  display: flex;
  width: 250px;
  margin-left: 50px;
  margin-top: -20px;
  margin-bottom: 50px;
  justify-content: space-between;
}

.trash-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 15px;
}

.comment-like-reply {
  width: 130px;
  height: 30px;
  align-items: center;
  display: flex;
  margin-left: 620px;
  gap: 20px;
}

.comment-like-reply p {
  margin-top: 20px;
}

.comment-reply {
  background-color: rgba(255, 255, 255, 0.384);
  border: 0px;
  font-size: 18px;
  font-family: 'Patua One', cursive;
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
}

.like-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

#all-comments {
  margin-left: -50px;
}

#picture-comment {
  display: flex;
  gap: 20px;
  margin-left: 50px;
  margin-top: 50px;
}

#picture-comment img {
  width: 50px;
  height: 50px;
}

#comment-body {
  width: 600px;
  height: 150px;
  border-radius: 5px;
  resize: none;
  font-family: 'Patua One', cursive;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.582);
}

#comment-buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding-right: 200px;
}

#comment-buttons button {
  width: 100px;
  height: 40px;
  border: 0;
  font-family: 'Patua One', cursive;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
}

#post {
  background-color: rgba(55, 55, 221, 0.473);
}

#cancel {
  background-color: rgba(128, 128, 128, 0.466);
}

#fake-cancel {
  background-color: rgba(128, 128, 128, 0.466);
}

#comment-loader-container {
  position: absolute;
  width: 600px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.39);
  padding-top: 50px;
  padding-left: 150px;
  border-radius: 5px;
}

#comment-loader {
  position: relative;
  width: 800px;
  height: 20px;
  border-radius: 10px;
  margin-top: -22px;
  margin-bottom: 25px;
  padding-left: 100px;
  background-color: transparent;
  z-index: 1;
}

#confirm-delete-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  justify-content: center;
  place-content: center;
}

#confirm-delete {
  padding: 50px;
  border-radius: 10px;
  background-color: rgba(219, 207, 207, 0.973);
  box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
  animation: confirm-delete 0.15s linear 1;
  text-align: center;
  z-index: 2;
}

#confirm-delete p {
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: -20px;
}

#confirm-delete-buttons {
  display: flex;
  gap: 35px;
  margin-top: 40px;
}

#confirm-delete-buttons button {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
}

#cancel-delete {
  background-color: gray;
}

#do-delete {
  background-color: rgba(5, 5, 212, 0.63);
}

#loader {
  position: relative;
  width: 800px;
  height: 20px;
  border-radius: 10px;
  margin-top: -22px;
  margin-bottom: 25px;
  padding-left: 100px;
  background-color: transparent;
  z-index: 1;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

#recipe-collections-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 100px;
  background-color: rgba(0, 0, 0, 0.425);
  place-content: center;
  z-index: 10;
}

#collections-container {
  width: 900px;
  height: 450px;
  background-color: rgba(255, 255, 255, 0.950);
  padding: 30px;
  border-radius: 16px;
  box-shadow: 40px 40px 40px rgba(8, 6, 27, 0.401);
}

#collections-container h4 {
  font-size: 25px;
  margin-top: -15px;
  margin-bottom: -15px;
  text-shadow: 4px 4px 4px rgba(8, 6, 27, 0.401);
  text-align: center;
}

#close-collections {
  place-self: right;
  margin-left: 820px;
  margin-top: -35px;
  margin-bottom: 0;
  font-size: 25px;
  cursor: pointer;
}

#collection-elements {
  display: flex;
  gap: 20px;
  place-content: center;
}

.collection-container {
  width: 180px;
  height: 300px;
  cursor: pointer;
  display: grid;
  place-items: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.185), rgba(0, 0, 0, 0.397));
  /* background-color: rgba(0, 0, 0, 0.185); */
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  transition: 0.2s;
}

.collection-container:hover {
  transform: scale(1.02);
  transition: 0.2s;
}

.collection-container h3 {
  color: rgb(88, 21, 21);
  max-width: 170px;
  line-break: anywhere;
}

.collection-container img {
  width: 150px;
  border-radius: 5px;
}

#no-collections-message {
  margin-top: 150px;
  height: 180px;
}

#new-collection {
  margin-left: 320px;
  width: 200px;
  height: 30px;
  border: 0;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
}

#new-collection-es {
  margin-left: 320px;
  width: 200px;
  height: 30px;
  border: 0;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
}

#manage-collections {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
  color: rgb(37, 9, 112);
  margin-left: 120px;
}

#recipe-login-option {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  justify-content: center;
  place-content: center;
}

#recipe-login-container {
  padding: 50px;
  border-radius: 10px;
  background-color: rgb(175, 212, 230);
  box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
  animation: confirm-delete 0.15s linear 1;
  text-align: center;
  z-index: 2;
}

#recipe-login-prompt {
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: -20px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 50px;
}

#recipe-login-container button {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  background-color: gray;
}

#recipe-confirmation-container {
  width: 400px;
  background-color: rgba(245, 237, 255, 0.985);
  position: fixed;
  top: 100px;
  left: 40%;
  padding: 10px;
  place-content: center;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.322);
}

#recipe-confirmation-container p {
  font-size: 20px;
  max-width: 380px;
  overflow-x: hidden;
}

/*Newest Recipes / Best Recipes*/
#newest-container {
  margin-top: 75px;
  display: grid;
  place-content: center;
}

#best-recipes-page-title {
  font-size: 45px;
  margin-left: 180px;
  text-shadow: 4px 4px 4px rgba(145, 42, 138, 0.493);
}

#best-recipes-page-title-es {
  font-size: 45px;
  margin-left: 120px;
  text-shadow: 4px 4px 4px rgba(145, 42, 138, 0.493);
}

#newest-recipes-page-title {
  font-size: 45px;
  margin-left: 220px;
  text-shadow: 4px 4px 4px rgba(145, 42, 138, 0.493);
}

#newest-recipes-page-title-es {
  font-size: 45px;
  margin-left: 150px;
  text-shadow: 4px 4px 4px rgba(145, 42, 138, 0.493);
}

#newest-recipes-container {
  border-radius: 16px;
  display: grid;
  padding: 30px;
}

.newest-recipe-container {
  width: 700px;
  height: 250px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  place-content: center;
  place-items: center;
  margin-bottom: 40px;
  background: linear-gradient(90deg, rgba(153, 214, 55, 0.603), rgba(18, 160, 25, 0.39));
  background-color: rgba(84, 184, 184, 0.473);
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(112, 65, 124, 0.616);
  transition: 0.2s;
}

.newest-recipe-container:hover {
  transform: scale(1.02);
  transition: 0.2s;
}

.best-recipe-container {
  width: 700px;
  height: 250px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  place-content: center;
  place-items: center;
  margin-bottom: 40px;
  background: linear-gradient(90deg, rgba(228, 183, 116, 0.589), rgba(230, 96, 43, 0.603));
  background-color: rgba(84, 184, 184, 0.473);
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(112, 65, 124, 0.616);
  transition: 0.2s;
}

.best-recipe-container:hover {
  transform: scale(1.02);
  transition: 0.2s;
}

.newest-recipe-name {
  font-size: 25px;
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-shadow: 3px 3px 3px rgba(124, 65, 101, 0.616);
  width: 500px;
}

.recipe-images-container {
  display: grid;
  place-self: center;
  place-content: center;
  margin-left: -30px;
  width: 300px;
  height: 150px;
  place-self: center;
  margin-right: 10px;
}

.recipe-images-container img {
  width: 150px;
  /* max-height: 200px; */
  place-self: center;
  margin-right: auto;
  border-radius: 5px;
}

.name-likes {
  max-height: 150px;
  display: grid;
  gap: 50px;
  margin-bottom: 20px;
  margin-top: -30px;
  margin-left: -50px;
}

.recipe-likes {
  font-size: 20px;
}

/*Search Page*/
#search-page-container {
  margin-top: 75px;
  display: grid;
  place-content: center;
}

#search-container {
  width: 500px;
  display: grid;
  gap: 5px;
}

#search-fields-container {
  display: flex;
  margin-bottom: 20px;
}

#filter-label {
  width: 150px;
  font-size: 25px;
  margin-left: -200px;
  margin-right: -20px;
  margin-top: 2px;
}

#filter-select {
  width: 150px;
  height: 30px;
  margin-right: 20px;
  margin-top: 2px;
  font-size: 18px;
  border: 1px solid;
  border-radius: 5px;
}

#search-field {
  font-size: 20px;
}

#search-page-title {
  margin-left: 100px;
  font-size: 40px;
  text-shadow: 3px 3px 3px rgba(124, 65, 101, 0.616);
}

#search-container input {
  width: 400px;
  height: 35px;
  border-radius: 10px;
}

#search-container img {
  cursor: pointer;
  border-radius: 10px;
  padding: 2px;
  background-color: rgba(87, 141, 16, 0.678);
}

#search-icon {
  width: 35px;
  border: 0.5px solid;
}

#search-results-container {
  display: grid;
}

.search-recipe-container {
  border: 1px solid;
  width: 400px;
  height: 200px;
  display: flex;
  place-content: left;
  border-radius: 16px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 8px 8px 8px rgba(91, 42, 104, 0.616);
  padding-left: 10px;
}

.search-recipe-container:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

.search-images-container {
  display: grid;
  place-content: center;
  width: 150px;
}

.search-result-image {
  height: 150px;
  width: 120px;
  margin-right: 10px;
}

.name-stats {
  margin-top: 10px;
  display: grid;
  justify-content: space-around;
}

.search-recipe-name {
  font-size: 25px;
}

.search-author-likes {
  display: flex;
  gap: 15px;
}

/*Dashboard*/
#dash-container {
  margin-top: 75px;
  display: grid;
  place-content: left;
  padding-left: 200px;
}

#dash-title {
  font-size: 50px;
  margin-left: 200px;
  text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
}

#dash-activity {
  width: 900px;
  font-size: 30px;
  margin-left: 15px;
  margin-top: -1px;
  text-shadow: 3px 3px 3px rgba(124, 65, 101, 0.616);
}

.activity-container {
  display: flex;
  max-width: 800px;
}

.activity-x {
  margin-top: 30px;
  margin-left: 20px;
  cursor: pointer;
  color: rgba(253, 49, 49, 0.793);
  font-weight: 900;
}

#activity-items-container {
  width: 900px;
  padding: 20px 30px 20px 80px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.288);
}

#all-activity-option {
  margin-top: 30px;
  margin-left: 30px;
  cursor: pointer;
  width: 200px;
  transition: 0.1s;
}

#all-activity-option:hover {
  transform: scale(1.05);
  transition: 0.1s;
}

.activity-item {
  font-size: 20px;
}

.dash-option {
  font-size: 30px;
  width: 400px;
  cursor: pointer;
  margin-left: 100px;
  text-shadow: 3px 3px 3px rgba(124, 65, 101, 0.616);
  margin-top: 30px;
  transition: 0.2s;
}

.dash-option:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#dash-collections {
  margin-bottom: 50px;
}

/*User Page*/
#user-page-container {
  margin-top: 100px;
  display: grid;
  place-content: center;
}

#user-info-container {
  width: 700px;
  height: fit-content;
  background-color: rgba(99, 22, 35, 0.253);
  border-radius: 16px;
  padding: 50px;
  box-shadow: 30px 30px 30px rgba(91, 42, 104, 0.616);
}

#name-image-flag {
  display: flex;
  width: 500px;
  gap: 20px;
  margin-bottom: 50px;
}

#user-page-image {
  width: 150px;
  border-radius: 500px;
}

#name-image-flag h1 {
  margin-top: 100px;
  text-shadow: 3px 3px 3px rgba(95, 65, 124, 0.616);
}

#country-flag {
  width: 50px;
  height: 20px;
  margin-top: 112px;
}

#about-user {
  width: 500px;
  height: 300px;
}

#about-title {
  font-size: 25px;
  text-shadow: 3px 3px 3px rgba(124, 65, 101, 0.616);
}

#user-page-recipes {
  width: 500px;
  height: fit-content;
}

#user-page-recipes-label {
  font-size: 25px;
  text-shadow: 3px 3px 3px rgba(124, 65, 101, 0.616);
}

#user-page-recipes a {
  font-size: 20px;
}

#user-recipes-container {
  display: grid;
  gap: 10px;
}

/*Favorites Page*/
#favorites-page-container {
  margin-top: 75px;
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
}

#favorites-container {
  width: 900px;
  background: linear-gradient(90deg, rgba(5, 78, 50, 0.192), rgba(59, 224, 161, 0.192));
  /* background-color: rgba(9, 199, 126, 0.192); */
  margin-top: 50px;
  padding: 50px;
  border-radius: 16px;
  box-shadow: 18px 18px 18px rgba(66, 28, 78, 0.616);
}

#favorites-title {
  font-size: 40px;
  margin-bottom: 50px;
  text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  margin-left: 200px;
}

.favorite-item-container {
  display: flex;
  height: 150px;
  transition: 0.2s;
}

.favorite-item-container img {
  width: 100px;
  height: 100px;
  margin-right: 50px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 8px 8px 8px rgba(91, 42, 104, 0.616);
}

.favorite-item-container:hover {
  transform: scale(1.03);
  transition: 0.2s;
}

.favorite-item {
  font-size: 25px;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 900;
  text-shadow: 3px 3px 3px rgba(83, 65, 124, 0.616);
}

/*My Recipes Page*/
#myrecipes-page-container {
  margin-top: 75px;
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
}

#myrecipes-container {
  width: 900px;
  background-color: rgba(9, 199, 126, 0.192);
  margin-top: 50px;
  padding: 50px;
  border-radius: 16px;
  box-shadow: 18px 18px 18px rgba(66, 28, 78, 0.616);
}

#myrecipes-title {
  font-size: 40px;
  margin-bottom: 50px;
  text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
  margin-left: 250px;
}

.myrecipe-item-container {
  display: flex;
  height: 150px;
  transition: 0.2s;
}

.myrecipe-item-container img {
  width: 100px;
  height: 100px;
  margin-right: 50px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 8px 8px 8px rgba(91, 42, 104, 0.616);
}

.myrecipe-item-container:hover {
  transform: scale(1.03);
  transition: 0.2s;
}

.myrecipe-item {
  font-size: 25px;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 900;
  text-shadow: 3px 3px 3px rgba(83, 65, 124, 0.616);
}

/*Account Page*/
#edit-user-container {
  margin-top: 75px;
  display: grid;
  padding-left: 100px;
}

#account-container {
  width: 550px;
  display: grid;
  margin-left: 100px;
  padding-left: 100px;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: rgba(38, 38, 184, 0.151);
  border-radius: 16px;
  padding-top: 30px;
  padding-bottom: 20px;
  box-shadow: 20px 20px 20px rgba(91, 42, 104, 0.616);
}

#edit-user-title {
  font-size: 40px;
  text-shadow: 5px 5px 5px rgba(69, 65, 124, 0.616);
  margin-left: 30px;
}

.account-option {
  font-size: 25px;
  font-weight: 900;
  text-shadow: 3px 3px 3px rgba(65, 91, 124, 0.616);
  transition: 0.2s;
  width: 350px;
  cursor: pointer;
  margin-top: 5px;
}

.account-option:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

#delete-page {
  margin-bottom: 30px;
}

#delete-page:hover {
  transform: scale(0.95);
  transition: 0.2s;
}

.update-field-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.582);
  position: fixed;
  top: 0px;
  left: 0px;
}

.account-content-container {
  width: 50vw;
  background: linear-gradient(90deg, rgba(228, 212, 230, 0.822), rgba(174, 158, 199, 0.89));
  border-radius: 10px;
  margin-left: 50vw;
  margin-top: 0px;
  height: 100vh;
  padding-top: 100px;
  padding-left: 80px;
  overflow: scroll;
}

#account-image-prompt {
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#account-avatars-imgs-container {
  width: fit-content;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.account-signup-avatar {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 50%;
}

.account-signup-avatar:hover {
  transform: scale(1.03);
  transition: 0.2s;
}

#account-image {
  width: 300px;
  height: 75px;
}

#account-current-image {
  width: 150px;
  height: 150px;
}

.account-image-input {
  font-size: 25px;
}

#new-account-image {
  width: 300px;
  height: 75px;
  margin-bottom: 300px;
}

#account-pictures-preview {
  width: 300px;
  height: 200px;
  margin-left: 350px;
  margin-top: -100px;
  margin-bottom: -40px;
}

.account-image-thumbnail img {
  width: 150px;
  height: 175px;
}

.account-X-container {
  width: 150px;
  color: red;
  margin-top: -40px;
  font-size: 25px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 900;
  text-align: right;
  padding-right: 10px;
  cursor: pointer;
  filter: opacity(80%);
  background-color: rgb(255, 255, 255);
  z-index: 10;
}

.account-buttons-container {
  width: 350px;
  height: 75px;
  display: flex;
  gap: 30px;
}

.account-buttons-container button {
  width: 160px;
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: 5px 5px 5px rgba(65, 91, 124, 0.616);
}

.account-cancel-button {
  background-color: gray;
}

.account-save-button {
  background-color: rgba(26, 38, 143, 0.521);
}

#account-current-country {
  display: flex;
  gap: 15px;
  margin-bottom: 50px;
}

#account-current-country p {
  font-size: 25px;
}

#account-country-prompt {
  font-size: 25px;
  margin-right: 20px;
}

#account-country {
  margin-bottom: 70px;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-weight: 700;
  font-size: 18px;
}

#account-about-container {
  display: grid;
  gap: 30px;
  margin-top: 70px;
}

#account-about {
  width: 550px;
  height: 180px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 18px;
  resize: none;
  margin-bottom: 50px;
}

#account-password-container {
  width: 550px;
  display: grid;
  gap: 50px;
  margin-top: 75px;
  margin-bottom: 70px;
}

.account-password-label {
  font-size: 25px;
}

#account-enter-password,
#account-password-confirm {
  display: flex;
}

#account-password,
#account-confirm-password {
  font-family: 'Patua One', cursive;
  font-size: 15px;
  width: 220px;
  height: 30px;
  margin-right: 0px;
  margin-left: auto;
  border-radius: 5px;
}

#pwd-mismatch-container {
  margin-top: -30px;
  width: 300px;
  height: 20px;
  margin-left: auto;
  margin-right: -50px;
}

#mismatch-label {
  color: red;
}

#account-delete-prompt {
  font-size: 25px;
  margin-top: 70px;
  margin-bottom: 70px;
}

#account-delete-container {
  width: 100%;
  height: 100%;
  position: fixed;
  place-content: center;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.363);
}

#account-confirm-delete {
  padding: 50px;
  border-radius: 10px;
  background-color: rgba(219, 207, 207, 0.973);
  box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
  animation: confirm-delete 0.15s linear 1;
  text-align: center;
  z-index: 2;
}

#account-confirm-delete p {
  font-size: 25px;
  margin-bottom: 35px;
  margin-top: -20px;
}

#account-confirm-delete-buttons {
  margin-left: 50px;
  display: flex;
  gap: 35px;
  margin-top: 40px;
}

#account-confirm-delete-buttons button {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
}

/*Collections Page*/
#collections-page-container {
  margin-top: 75px;
  display: grid;
  place-content: center;
}

#collections-page-title {
  font-size: 40px;
  place-self: center;
  text-shadow: 3px 3px 3px rgba(65, 91, 124, 0.616);
}

#user-collections-container {
  width: 1500px;
  height: 500px;
  padding: 50px;
  display: flex;
  gap: 30px;
  justify-content: center;
}

.user-collection-container {
  width: 300px;
  height: 450px;
  padding: 20px;
  padding-bottom: 70px;
  display: grid;
  gap: 10px;
  place-content: center;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 10px 10px 10px rgba(91, 42, 104, 0.616);
  background-color: rgba(255, 255, 255, 0.349);
}

.user-collection-container:hover {
  transform: scale(1.03);
  transition: 0.2s;
}

.user-collection-title {
  font-size: 25px;
  place-self: center;
  max-width: 300px;
  line-break: anywhere;
  max-height: 70px;
  overflow: auto;
}

.user-collection-title::-webkit-scrollbar {
  display: none;
}

#collection-edit-title {
  width: 25px;
  height: 25px;
  margin-top: 30px;
  margin-left: 5px;
  opacity: 75%;
  z-index: 10;
  position: absolute;
  right: -7px;
  bottom: 30px;
}

#collection-edit-image {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 5px;
  opacity: 75%;
  z-index: 10;
}

.collection-image-container {
  display: grid;
  place-content: center;
}

.user-collection-image {
  border-radius: 5px;
  width: 200px;
  place-self: center;
}

#new-collection-button {
  width: 300px;
  height: 50px;
  font-size: 25px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  place-self: center;
  margin-top: 50px;
  cursor: pointer;
  box-shadow: 5px 5px 5px rgba(91, 42, 104, 0.616);
  transition: 0.2s;
  background-color: rgba(170, 102, 102, 0.589);
  margin-bottom: 40px;
}

#new-collection-button:hover {
  transform: scale(1.02);
  transition: 0.2s;
}

#collection-displayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.493);
  place-content: center;
}

#container-collection {
  margin-top: 100px;
  width: 1000px;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.944);
  border-radius: 16px;
  display: grid;
  place-content: center;
  background: linear-gradient(90deg, rgb(148, 179, 224), rgb(161, 169, 180));
  box-shadow: 35px 35px 35px rgba(36, 30, 90, 0.616);
}

#container-collection-title {
  width: 500px;
  height: 100px;
  margin-top: 0px;
  margin-left: 350px;
  font-size: 30px;
  margin-bottom: -80px;
  margin-left: 300px;
  text-shadow: 3px 3px 3px rgba(36, 30, 90, 0.616);
}

#collection-recipes-container {
  width: 730px;
  height: 400px;
  overflow-y: auto;
  /* height: fit-content; */
  padding-bottom: 30px;
  display: grid;
  gap: 10px;
  place-self: center;
  margin-top: 40px;
  margin-bottom: 50px;
}

#collection-recipes-container::-webkit-scrollbar {
  display: none;
}

#options-container-collection {
  display: flex;
  gap: 40px;
  width: 100px;
  height: 40px;
  margin-left: 800px;
  padding-top: 100px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-top: -60px;
  margin-bottom: -30px;
}

#edit-collection-option {
  font-size: 18px;
  margin-bottom: -50px;
  margin-top: 30px;
  cursor: pointer;
  color: rgb(50, 50, 163);
}

/* #collection-name {

} */

#close-container-collection {
  font-size: 25px;
  margin-top: 100px;
  cursor: pointer;
  margin-top: -20px;
  color: rgb(170, 26, 26);
}

#save-container-collection {
  font-size: 25px;
  font-weight: 900;
  margin-top: -20px;
  cursor: pointer;
  color: rgb(27, 143, 27);
}

.collection-recipe {
  width: 700px;
  height: 150px;
  display: flex;
  border-radius: 10px;
  background-color: rgb(118, 164, 204);
  padding: 25px;
  gap: 20px;
  box-shadow: 5px 5px 5px rgba(55, 48, 146, 0.616);
}

.collection-recipe h5 {
  font-size: 20px;
}

.collection-recipe-image {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.collection-recipe-name-view {
  display: grid;
  margin-top: -30px;
  position: relative;
}

.collection-recipe-name-view p {
  cursor: pointer;
  font-size: 18px;
  width: 120px;
}

.collection-recipe-name-view h5 {
  max-width: 350px;
}

.remove-recipe {
  opacity: 80%;
  filter: brightness(50%);
}

.collection-recipe-remove {
  margin-right: 0px;
  margin-left: auto;
  margin-top: 85px;
  color: rgb(187, 15, 15);
  cursor: pointer;
  height: 30px;
  width: fit-content;
}

.collection-recipe-remove-es {
  margin-right: 0px;
  margin-left: auto;
  margin-top: 85px;
  color: rgb(187, 15, 15);
  cursor: pointer;
  height: 30px;
  width: fit-content;
}

#collection-confirmation-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  justify-content: center;
  place-content: center;
}

#collection-recipes-confirm-delete {
  padding: 50px;
  border-radius: 10px;
  background-color: rgba(219, 207, 207, 0.973);
  box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
  animation: confirm-delete 0.15s linear 1;
  text-align: center;
  z-index: 2;
}

#collection-recipes-confirm-delete p {
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: -20px;
}

#collection-confirm-delete-buttons {
  display: flex;
  gap: 35px;
  margin-top: 40px;
}

#collection-confirm-delete-buttons-es {
  display: flex;
  gap: 35px;
  margin-top: 40px;
}


#collection-confirm-delete-buttons button {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
}

#collection-confirm-delete-buttons-es button {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
}

#collection-cancel-delete {
  background-color: gray;
}

#collection-do-delete {
  background-color: rgba(5, 5, 212, 0.63);
}

#new-collection-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.637);
  top: 0px;
  left: 0px;
  display: grid;
  place-content: center;
}

#new-collection-div {
  width: 500px;
  height: 600px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.932);
  display: grid;
  place-content: center;
  /* margin-top: 150px;
  margin-left: 35vw; */
}

#new-collection-form {
  height: 500px;
  display: grid;
  padding-left: 0px;
}

.new-collection-form-input {
  font-size: 25px;
}

#image-options {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  height: 300px;
  margin-right: -30px;
  margin-top: 0px;
  margin-bottom: -120px;
  margin-left: 20px;
}

.collection-image {
  width: 150px;
  height: 150px;
  margin-right: 30px;
  transition: 0.2s;
  cursor: pointer;
}

.collection-image:hover {
  transform: scale(1.06);
  transition: 0.2s;
}

.selected-image {
  border: 2px solid red;
  transform: scale(1.06);
}

#new-collection-name {
  height: 40px;
  width: 350px;
  border-radius: 5px;
  margin-top: -70px;
  font-family: 'Patua One', cursive;
  font-size: 18px;
}

#collection-pictures-container {
  margin-top: -70px;
  display: grid;
}

#new-collection-buttons {
  margin-bottom: 0px;
  margin-top: auto;
  margin-left: 30px;
  display: flex;
  gap: 15px;
}

#new-collection-submit {
  font-family: 'Patua One', cursive;
  text-align: center;
  border: 2px solid;
  width: 150px;
  height: 35px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(41, 41, 189, 0.699);
}

#new-collection-cancel {
  font-family: 'Patua One', cursive;
  text-align: center;
  padding-top: 3px;
  border: 2px solid;
  width: 150px;
  height: 35px;
  border-radius: 5px;
  font-size: 19px;
  cursor: pointer;
  background-color: gray;
}

.collection-X-container {
  width: 180px;
  color: red;
  margin-top: -40px;
  font-size: 25px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 900;
  text-align: right;
  padding-right: 10px;
  cursor: pointer;
  filter: opacity(80%);
  background-color: rgb(255, 255, 255);
  z-index: 10;
}

#edit-collection-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.559);
  display: grid;
  place-content: center;
}

#edit-collection-div {
  width: 400px;
  height: 550px;
  margin-top: 50px;
  ;
  background-color: rgba(255, 255, 255, 0.947);
  display: grid;
  place-content: center;
  border-radius: 16px;
}

#edit-collection-title {
  display: grid;
  place-content: center;
  gap: 15px;
  margin-top: -380px;
}

.edit-collection-label {
  font-size: 20px;
}

#edit-collection-name {
  width: 300px;
  height: 30px;
  border-radius: 5px;
  font-family: 'Patua One', cursive;
  font-size: 18px;
  z-index: 10;
}

#edit-image-container {
  width: 300px;
  height: 300px;
  display: grid;
  place-content: center;
  margin-top: -270px;
  margin-bottom: 30px;
  margin-left: 50px;
}

.edit-image-container {
  width: 400px;
  height: 300px;
}

#collection-image-options {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  height: 250px;
  margin-right: -30px;
  margin-top: 0px;
  margin-bottom: -120px;
  margin-left: -20px;
  margin-bottom: -300px;
}

.collection-image-option {
  width: 130px;
  height: 130px;
  margin-right: 30px;
  transition: 0.2s;
  cursor: pointer;
  margin-left: 30px;
  margin-right: -20px;
  z-index: 5;
}

.collection-image-option:hover {
  transform: scale(1.06);
  transition: 0.2s;
}

#edit-buttons-container {
  width: 300px;
  height: 75px;
  margin-top: auto;
  margin-bottom: -200px;
  display: grid;
  gap: 15px;
  margin-left: 20px;
}

#save-cancel-buttons {
  display: flex;
  gap: 60px;
  margin-bottom: 10px;
}

#edit-collection-cancel,
#edit-collection-save {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

#edit-collection-cancel {
  background-color: gray;
}

#edit-collection-save {
  background-color: rgb(93, 93, 210);
}

#edit-collection-delete {
  background-color: rgba(233, 48, 23, 0.512);
  border-radius: 5px;
  width: 175px;
  height: 30px;
  color: rgb(61, 61, 61);
  place-self: center;
  cursor: pointer;
}

#confirm-delete-collection {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  justify-content: center;
  place-content: center;
}

#collection-confirm-delete {
  padding: 50px;
  border-radius: 10px;
  background-color: rgba(219, 207, 207, 0.973);
  box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
  animation: confirm-delete 0.15s linear 1;
  text-align: center;
  z-index: 2;
}

#collection-confirm-delete p {
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: -20px;
}

#collection-delete-buttons {
  display: flex;
  gap: 35px;
  margin-top: 40px;
}

#collection-delete-buttons button {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
}

.collection-cancel-delete {
  background-color: gray;
}

.collection-do-delete {
  background-color: rgba(5, 5, 212, 0.63);
}

#display-limit-container {
  width: 100%;
  height: 100%;
  position: fixed;
  place-content: center;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.363);
}

#display-limit {
  width: 400px;
  height: 150px;
  background-color: rgb(211, 211, 211);
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  text-align: center;
  box-shadow: 30px 30px 30px rgba(8, 6, 27, 0.616);
}

#display-limit p {
  font-size: 18px;
}

#display-limit button {
  width: 120px;
  height: 25px;
  place-self: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(110, 96, 235, 0.808);
}

/*Activity Page*/
#activity-show-more {
  font-size: 18px;
  margin-top: 30px;
  cursor: pointer;
}

#opt-out-option {
  display: flex;
  margin-left: 550px;
  margin-top: -110px;
}

#opt-out-option p {
  margin-top: 45px;
  margin-left: 10px;
}

/*Edit Recipe*/
#recipe-delete-confirmation {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: grid;
  justify-content: center;
  place-content: center;
}

#recipe-delete-container {
  padding: 50px;
  border-radius: 10px;
  background-color: rgba(219, 207, 207, 0.973);
  box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
  animation: confirm-delete 0.15s linear 1;
  text-align: center;
  z-index: 2;
}

#recipe-delete-container p {
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: -20px;
}

#delete-recipe-buttons {
  display: flex;
  gap: 35px;
  margin-top: 40px;
}

#delete-recipe-buttons button {
  width: 150px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
}

#cancel-recipe {
  background-color: gray;
}

#delete-recipe {
  background-color: rgba(5, 5, 212, 0.63);
}

/*Account Delete Confirmation*/
#account-confirmation-container {
  width: 500px;
  height: 300px;
  margin-top: 150px;
  margin-left: 40%;
}

#delete-account-image {
  width: 500px;
  margin-left: -100px;
}

#account-confirmation-container p {
  font-size: 30px;
  width: 700px;
  margin-left: -100px;
}

/*404 page*/
#missing-page-container {
  width: 100vw;
  height: 100%;
  display: grid;
  place-content: center;
  padding-top: 15%;
}

#chef-sleep {
  width: 250px;
  height: 500px;
  margin-top: -100px;
  place-self: center;
}

#missing-page-container p {
  font-size: 30px;
  text-align: center;
  max-width: 300px;
}

#missing-page-container button {
  width: fit-content;
  place-self: center;
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  font-size: 30px;
}

/*Missing User*/
#missing-user-container {
  width: 100vw;
  height: 100%;
  display: grid;
  place-content: center;
  padding-top: 20%;
}

#missing-user {
  width: 250px;
  height: 250px;
  margin-top: -100px;
  place-self: center;
}

#missing-user-container p {
  font-size: 30px;
  text-align: center;
  max-width: 500px;
}

#missing-user-container button {
  width: fit-content;
  place-self: center;
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  font-size: 30px;
}

@media screen and (max-width: 1200px) {
  #account-about {
    width: 450px;
  }

  #account-password {
    margin-left: 0px;
  }

  #account-confirm-password {
    margin-left: 40px;
  }
}

@media screen and (max-height: 800px) and (min-width: 700px) {

  #welcome-title {
    height: 700px;
  }

  #welcome-search {
    height: 700px;
  }

  #welcome-share {
    height: 700px;
  }

  #welcome-comunity {
    height: 700px;
  }

  #intro {
    margin-top: 0px;
  }

  #search-title {
    margin-bottom: 0px;
  }

  #scroll-container {
    margin-top: 30px;
  }

  #welcome-share-prompt {
    margin-bottom: 0px;
  }

  #welcome-share-image img {
    height: 400px;
  }

  #welcome-share-button {
    margin-top: -100px;
  }

  #welcome-community-image img {
    height: 300px;
    width: 300px;
    margin-left: 40px;
  }

  #welcome-community-button {
    margin-top: -200px;
    margin-bottom: -100px;
  }

  #welcome-community-prompt {
    margin-top: 0px;
  }
}

@media screen and (max-width: 700px) {

  /*Header*/
  #page-header {
    width: 100%;
  }

  #header-container {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  #favorites {
    width: 280px;
    max-width: 280px;
    margin-right: -200px;
  }

  #flag-icon {
    display: block;
    margin-left: 90px;
    margin-top: -40px;
  }

  #flag-icon p {
    margin-left: 5px;
  }

  #flag-choice {
    margin-left: 50px;
  }

  .header-option {
    display: none;
  }

  #header-icon {
    display: inline;
    width: 50px;
    margin-left: -30px;
  }

  #header-dots {
    cursor: pointer;
  }

  #dot-options {
    width: 210px;
    height: 140px;
    background-color: rgb(244, 244, 244);
    border: 1px solid;
    border-radius: 5px;
    padding-left: 10px;
    position: absolute;
    top: 75px;
    left: 20px;
  }

  .dot-option {
    margin-top: 10px;
    width: 200px;
    font-size: 20px;
    color: black;
    cursor: pointer;
  }

  .dot-option:hover {
    transform: scale(1.03);
    transition: 0.2s;
  }

  #search-account {
    place-self: left;
    min-width: 100px;
    display: flex;
    margin-left: -550px;
  }

  #header-search-icon {
    display: inline;
    margin-right: -30px;
    z-index: 3;
  }

  #login {
    display: inline;
    align-self: top;
    margin-top: 0px;
    /* max-width: 100px; */
  }

  #login-es {
    display: inline;
    align-self: top;
    margin-top: -5px;
    max-width: 100px;
    font-size: 20px;
  }

  #user-options {
    height: fit-content;
    margin-left: 0px;
  }


  #user-options button {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    vertical-align: top;
    height: fit-content;
  }

  /*Welcome Page*/
  .page-container {
    width: 100%;
  }

  #welcome {
    width: 100%;
    height: 100vh;
  }

  #icon {
    width: 200px;
  }

  #icon:hover {
    transform: scale(1.03);
    transition: 0.3s;
  }

  #main-title {
    font-size: 45px;
    margin-top: -150px;
    margin-left: 0px;
    max-width: 320px;
    text-shadow: 3px 3px 3px rgba(117, 25, 25, 0.603);
  }

  #main-title:hover {
    transform: scale(1.01);
    transition: 0.3s;
  }

  #intro {
    font-size: 18px;
    margin-top: -150px;
  }

  #main-welcome {
    width: 100%;
  }

  #welcome-search {
    background: linear-gradient(90deg, rgba(219, 175, 243, 0.315), rgba(102, 101, 209, 0.459));
    width: 100%;
    height: 100vh;
    display: grid;
    box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.11);
    place-content: center;
    place-items: center;
  }

  #search-title {
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  #scroll-container {
    height: 350px;
    margin-top: 80px;
  }

  #scroll-left {
    height: 350px;
    width: 70px;
    margin-left: 20px;
  }

  #scroll-left:hover {
    opacity: 100%;
  }

  #scroll-left img {
    width: 50px;
    margin-top: -100px;
    margin-bottom: 100px;
  }

  #scroll-right {
    height: 350px;
    width: 70px;
    margin-left: 0px;
    margin-right: -18px;
  }

  #scroll-right:hover {
    opacity: 100%;
  }

  #scroll-right img {
    width: 50px;
    margin-top: -100px;
    margin-bottom: 100px;
  }

  #welcome-option-container {
    height: 450px;
    width: 100vw;
    max-width: 450px;
  }

  #welcome-option-container::-webkit-scrollbar {
    display: none;
  }

  .welcome-option {
    width: 300px;
    min-width: 300px;
    height: 450px;
  }

  .option-label {
    margin-top: 0px;
  }

  #welcome-share {
    background: linear-gradient(90deg, rgba(186, 148, 207, 0.315), rgba(66, 65, 139, 0.459));
    height: 100vh;
    box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.171);
    display: grid;
    place-content: center;
    place-items: center;
  }

  #welcome-share-prompt {
    font-size: 22px;
    max-width: 300px;
  }

  #welcome-share-image {
    height: 400px;
  }

  #welcome-share-image img {
    border-radius: 10px;
    height: 300px;
    margin-bottom: 20px;
  }

  #welcome-share-button {
    width: 220px;
    height: 50px;
    font-size: 20px;
    margin-top: -100px;
  }

  #welcome-share-button:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #welcome-comunity {
    background: linear-gradient(90deg, rgba(219, 190, 235, 0.315), rgba(121, 95, 155, 0.459));
    height: 100vh;
    box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.171);
    display: grid;
    place-content: center;
    place-items: center;
  }

  #welcome-community-prompt {
    font-size: 20px;
    max-width: 280px;
    margin-top: 50px;
  }

  #welcome-community-image-container {
    width: 300px;
    margin-top: -30px;
  }

  #welcome-community-image {
    width: 300px;
    height: 350px;
    margin-left: -100px;
    margin-bottom: 40px;
  }

  #welcome-community-image img {
    width: 250px;
    height: 280px;
    margin-left: 70px;
    margin-top: -20px;
  }

  #welcome-community-button {
    width: 140px;
    height: 50px;
    font-size: 20px;
    margin-top: -150px;
    margin-bottom: 0px;
  }

  #welcome-community-button:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #welcome-store {
    background: linear-gradient(90deg, rgba(171, 122, 197, 0.315), rgba(105, 64, 158, 0.459));
    height: 100vh;
    box-shadow: 0px 15px 15px rgba(124, 65, 65, 0.171);
    display: grid;
    place-content: center;
    place-items: center;
  }

  #welcome-store-prompt {
    font-size: 30px;
    margin-top: 0px;
    max-width: 700px;
    text-align: center;
  }

  #welcome-store-image {
    width: 300px;
    height: 400px;
    border: 1px white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.26);
    margin-bottom: 40px;
  }

  #welcome-store-button {
    cursor: pointer;
    width: 250px;
    height: 70px;
    background-color: rgba(196, 221, 52, 0.548);
    border-radius: 10px;
    font-size: 25px;
    font-family: 'Patua One', cursive;
    box-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
    transition: 0.2s;
  }

  #welcome-store-button:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  /*Newest Recipes / Best Recipes*/
  #newest-container {
    width: 300px;
    max-width: 300px;
  }

  #best-recipes-page-title {
    font-size: 30px;
    margin-left: 50px;
    width: 280px;
    max-width: 290px;
  }

  #best-recipes-page-title-es {
    font-size: 30px;
    margin-left: 50px;
    width: 280px;
    max-width: 290px;
    text-align: center;
  }

  #newest-recipes-page-title {
    font-size: 30px;
    margin-left: 50px;
    width: 280px;
    max-width: 290px;
  }

  #newest-recipes-page-title-es {
    font-size: 30px;
    margin-left: 50px;
    width: 280px;
    max-width: 290px;
    text-align: center;
  }

  #newest-recipes-container {
    width: 100%;
    max-width: 350px;
    margin: 0px;
  }

  .newest-recipe-container {
    width: 300px;
    margin-left: 10px;
    height: 150px;
    margin-bottom: 20px;
  }

  .newest-recipe-container:hover {
    transform: scale(1.02);
    transition: 0.2s;
  }

  .best-recipe-container {
    width: 300px;
    margin-left: 10px;
    height: 150px;
    margin-bottom: 20px;
  }

  .best-recipe-container:hover {
    transform: scale(1.02);
    transition: 0.2s;
  }

  .newest-recipe-name {
    font-size: 20px;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-shadow: 3px 3px 3px rgba(124, 65, 101, 0.616);
    width: 280px;
  }

  .recipe-images-container {
    width: 250px;
  }

  .recipe-images-container img {
    width: 100px;
  }

  .name-likes {
    max-height: 100px;
    max-width: 280px;
    display: grid;
    gap: 20px;
    margin-left: -50px;
    position: relative;
  }

  .newest-recipe-name {
    max-width: 180px;
  }

  .recipe-likes {
    font-size: 16px;
    position: absolute;
    bottom: -60px;
  }

  /*Recipe Page*/
  #recipe-container {
    width: 300px;
    max-width: 300px;
    padding: 0px;
    padding-left: 0px;
    margin-left: 0px;
    display: grid;
    place-items: start;
    box-shadow: 10px 10px 10px rgba(124, 65, 101, 0.616);
  }

  #name-edit {
    margin-left: 0px;
  }

  #recipe-title {
    font-size: 25px;
    margin-top: 30px;
    width: 250px;
    max-width: 250px;
    text-align: center;
  }

  #pencil-icon {
    margin-left: -15px;
    width: 25px;
    height: 25px;
  }

  .recipe-label {
    font-size: 20px;
    margin-left: 20px;
  }

  #recipe-cooking-time {
    display: grid;
    gap: 0px;
    place-content: left;
    justify-content: start;
  }

  #recipe-cooking-time p {
    max-width: 140px;
  }

  #cooking-time-label {
    font-size: 15px;
    margin-left: 30px;
    margin-top: -20px;
    margin-bottom: 40px;
  }

  #cooking-time-label-es {
    font-size: 15px;
    margin-left: 30px;
    margin-top: -20px;
    margin-bottom: 40px;
  }

  #serving-label {
    font-size: 15px;
  }

  #recipe-category {
    font-size: 18px;
    margin-top: -5px;
  }

  #time-servings {
    gap: 200px;
    display: grid;
    width: 280px;
    place-content: left;
    margin-top: 50px;
    margin-left: 0px;
  }

  #time-servings-picture {
    width: 280px;
    margin-left: 0px;
    margin-bottom: -100px;
    padding-left: 0px;
  }

  #thumbnails-container {
    width: 200px;
    display: flex;
    justify-content: center;
    gap: 0px;
    margin-left: 0px;
    margin-bottom: 50px;
  }

  .recipe-thumbnail {
    width: 100px;
    margin-top: 50px;
    margin-left: 0px;
  }

  .recipe-thumbnail img {
    width: 100px;
    height: 100px;
    margin-right: 0px;
  }

  #ingredients-container {
    width: 250px;
    max-width: 250px;
    margin-left: 20px;
    margin-right: 0px;
    grid-template-columns: 300px;
    white-space: wrap;
    line-break: auto;
    overflow-x: inherit;
    line-height: 25px;
  }

  #ingredient-units-type {
    width: 100px;
    margin-left: 180px;
  }

  .ingredient {
    font-size: 15px;
    width: 240px;
    max-height: 50px;
  }

  #instructions {
    margin-left: 20px;
    font-size: 18px;
    width: 280px;
    margin-top: -30px;
  }

  #instructions div p {
    margin-top: 12px;
    margin-bottom: 0px;
    font-size: 15px;
    max-width: 250px;
  }

  #author {
    place-self: start;
    margin-left: 100px;
    margin-right: -100px;
    max-width: 200px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #likes-comments-share {
    width: 200px;
  }

  #share-options-container {
    margin-left: 50px;
  }

  #coments-container {
    width: 280px;
    margin-left: 0px;
  }

  #all-comments {
    width: 300px;
    margin-left: 0px;
    padding-left: 25px;
  }

  .comment-container {
    width: 250px;
    height: auto;
  }

  .comment-user-content {
    display: flex;
    width: 250px;
    margin-left: -20px;
  }

  #comment-body {
    width: 200px;
  }

  .comment-date-delete {
    justify-content: right;
  }

  .comment-image-content {
    width: 50px;
  }

  .comment-user {
    width: 40px;
    height: 40px;
  }

  .comment-content {
    font-size: 15px;
    width: 150px;
    max-width: 150px;
    height: auto;
    overflow-wrap: break-word;
    margin-right: -200px;
  }

  .comment-date-delete {
    margin-top: -50px;
  }

  .comment-date {
    font-size: 14px;
  }

  .comment-like-reply {
    width: 130px;
    height: 30px;
    align-items: center;
    display: flex;
    margin-left: 100px;
    gap: 20px;
  }

  .comment-like-reply p {
    font-size: 14px;
  }

  .comment-reply {
    font-size: 14px;
  }

  #show-more-button {
    font-size: 15px;
  }

  #picture-comment {
    margin-left: 0px;
    margin-right: 0px;
  }

  #picture-comment img {
    width: 40px;
    height: 40px;
  }

  #comment-body {
    font-size: 18px;
  }

  #comment-buttons {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    padding-right: 0px;
    margin-right: 40px;
  }

  #comment-buttons button {
    width: 90px;
    height: 40px;
    border: 0;
    font-family: 'Patua One', cursive;
    font-weight: 900;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  #comment-loader-container {
    width: 200px;
    padding-left: 0px;
  }

  #comment-loader {
    width: 200px;
    padding-left: 70px;
  }

  #confirm-delete {
    width: 300px;
    height: 180px;
  }

  #confirm-delete p {
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: -20px;
  }

  #confirm-delete-buttons {
    display: flex;
    gap: 35px;
    margin-top: -10px;
  }

  #confirm-delete-buttons button {
    width: 100px;
    height: 25px;
    font-size: 18px;
  }

  #loader {
    width: 300px;
  }

  #collections-container {
    width: 300px;
    height: 500px;
  }

  #collections-container h4 {
    font-size: 20px;
  }

  #close-collections {
    margin-left: 230px;
    margin-top: -30px;
    font-size: 20px;
  }

  #collection-elements {
    height: 400px;
    overflow-y: auto;
    justify-content: start;
  }

  #no-collections-message {
    margin-top: 0px;
    text-align: center;
    place-self: center;
    height: 10px;
    font-size: 18px;
    margin-left: 10px;
  }

  .collection-container {
    width: 180px;
    height: 300px;
    cursor: pointer;
    display: grid;
    place-items: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.185), rgba(0, 0, 0, 0.397));
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    transition: 0.2s;
  }

  .collection-container:hover {
    transform: scale(1.02);
    transition: 0.2s;
  }

  .collection-container h3 {
    color: rgb(88, 21, 21);
    max-width: 170px;
    line-break: anywhere;
  }

  .collection-container img {
    width: 150px;
    border-radius: 5px;
  }

  #new-collection {
    width: 150px;
    margin-left: -30px;
    margin-bottom: -300px;
    margin-top: 20px;
  }

  #new-collection-es {
    width: 180px;
    margin-left: -30px;
    margin-bottom: -300px;
    margin-top: 0px;
  }

  #manage-collections {
    position: absolute;
    width: 120px;
    right: 20px;
  }

  #recipe-login-container {
    width: 280px;
    height: 150px;
  }

  #recipe-login-prompt {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  #recipe-login-container button {
    width: 100px;
    height: 25px;
    font-size: 18px;
  }

  #recipe-confirmation-container {
    width: 400px;
    background-color: rgba(245, 237, 255, 0.985);
    position: fixed;
    top: 100px;
    left: 40%;
    padding: 10px;
    place-content: center;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.322);
  }

  #recipe-confirmation-container p {
    font-size: 20px;
    max-width: 380px;
    overflow-x: hidden;
  }

  /*Login Page*/
  #login-container {
    width: 100vw;
    max-width: 340px;
  }

  #login-title {
    font-size: 25px;
    text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
    margin-top: -100px;
    margin-left: 100px;
  }

  #login-title-es {
    font-size: 25px;
    text-shadow: 5px 5px 5px rgba(124, 65, 101, 0.616);
    margin-top: -100px;
    margin-left: 50px;
  }

  #login-form {
    width: 320px;
    max-width: 340px;
    padding-left: 20px;
    padding-top: 30px;
  }

  #login-form label {
    font-size: 20px;
  }

  .form-input {
    width: 280px;
  }

  #form-submit-button {
    width: 150px;
    height: 40px;
  }

  #create-account-container {
    margin-left: 70px;
    width: 150px;
  }

  #back-container {
    font-size: 25px;
    margin-left: 80px;
    margin-top: 50px;
  }

  #verify-account-container {
    width: 100%;
    height: 100%;
    position: fixed;
    place-content: center;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.363);
  }

  #verify-username-container {
    width: 300px;
    height: 250px;
  }

  #verify-username-container p {
    font-size: 18px;
  }

  #verify-username {
    margin-bottom: 20px;
    width: 250px;
  }

  #verify-buttons-container {
    width: 200px;
  }

  #cancel-verify {
    width: 150px;
    height: 30px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    background-color: rgba(128, 128, 128, 0.918);
  }

  #verify-submit {
    width: 150px;
    height: 30px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    background-color: rgba(110, 96, 235, 0.808);
  }

  #new-pwd-container {
    width: 300px;
  }

  #new-pwd-container p {
    font-size: 18px;
  }

  #new-pwd {
    margin-bottom: 30px;
    width: 250px;
  }

  #confirm-new-pwd {
    margin-bottom: 30px;
    width: 250px;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.219);
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  #require-username-container {
    width: 100%;
    height: 100%;
    position: fixed;
    place-content: center;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.363);
  }

  #require-username {
    width: 300px;
    height: 150px;
    background-color: rgb(211, 211, 211);
    border-radius: 10px;
    padding-left: 70px;
    padding-top: 30px;
    box-shadow: 30px 30px 30px rgba(43, 32, 38, 0.616);
  }

  #require-username p {
    font-size: 18px;
  }

  #require-username button {
    width: 120px;
    height: 25px;
    place-self: center;
    margin-left: 20px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(110, 96, 235, 0.808);
  }

  #password-mismatch-container {
    width: 100%;
    height: 100%;
    position: fixed;
    place-content: center;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.363);
  }

  #password-mismatch {
    width: 320px;
    height: 150px;
    background-color: rgb(211, 211, 211);
    border-radius: 10px;
    padding-left: 70px;
    padding-top: 30px;
  }

  #password-mismatch p {
    font-size: 18px;
    width: 350px;
    justify-self: center;
    align-self: center;
    place-self: center;
    text-align: middle;
    margin-left: -35px;
  }

  #password-mismatch button {
    width: 120px;
    height: 25px;
    place-self: center;
    margin-left: 30px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(110, 96, 235, 0.808);
  }

  #password-success-container {
    width: 100%;
    height: 100%;
    position: fixed;
    place-content: center;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.363);
  }

  #password-success {
    width: 300px;
    height: 150px;
    background-color: rgb(211, 211, 211);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    line-height: 25px;
    text-align: center;
    box-shadow: 30px 30px 30px rgba(43, 32, 38, 0.616);
  }

  #password-success p {
    font-size: 18px;
  }

  #password-success button {
    width: 120px;
    height: 25px;
    place-self: center;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(110, 96, 235, 0.808);
  }

  #unverified-container {
    width: 300px;
    padding: 30px;
  }

  #unverified-container p {
    font-size: 18px;
    max-width: 250px;
  }

  #unverified-container select {
    width: 250px;
  }

  #unverified-buttons {
    width: 200px;
    gap: 20px;
    margin-left: 20px;
  }

  #unverified-buttons button {
    width: 120px;
    height: 30px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
  }

  #verify-recipe {
    background-color: rgba(131, 87, 233, 0.822);
  }

  #failed {
    width: 300px;
    padding-left: 20px;
    padding-top: 20px;
  }

  #failed p {
    font-size: 18px;
    margin-bottom: -20px;
  }

  #failed a {
    font-size: 18px;
  }

  /*New User Page*/

  #new-user-title {
    font-size: 30px;
    margin-left: 40px;
    margin-bottom: 50px;
  }

  #new-user-form {
    width: 300px;
    padding: 20px;
    margin-left: 0px;
  }

  .new-user-form-input {
    font-size: 20px;
  }

  .new-user-form-value {
    width: 250px;
    font-size: 18px;
  }

  #countries {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    font-family: 'Patua One', cursive;
    font-weight: 700;
    font-size: 18px;
  }

  #about {
    max-width: 250px;
  }

  #avatar-prompt {
    font-size: 20px;
  }

  #avatars-imgs-container {
    padding-left: 30px;
  }

  .signup-avatar {
    width: 90px;
    height: 90px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 500px;
  }

  .signup-avatar:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  .selected-avatar {
    border-radius: 500px;
    border: 5px solid red;
  }

  #pictures-input label {
    font-size: 20px;
  }

  .image-thumbnail {
    width: 80px;
    height: 100px;
  }

  .image-thumbnail img {
    width: 80px;
    height: 100px;
    margin-left: -30px;
  }

  .X-container {
    width: 80px;
    margin-left: -30px;
  }

  .X-container p {
    width: 80px;
  }

  #new-user-submit:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #login-option-container {
    margin-left: 20px;
  }

  #missing-login-container {
    width: 300px;
  }


  #missing-login-container button {
    background-color: rgba(5, 5, 212, 0.63);
    width: 120px;
  }

  #missing-login {
    font-size: 18px;
  }

  #ok-login {
    width: 150px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 25px;
  }

  #signup-loading {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    margin-top: 0px;
    margin-top: 0px;
    background-color: rgba(0, 0, 0, 0.312);
    padding-top: 25%;
    padding-left: 40%;
  }

  .signup-lds-dual-ring {
    margin-left: -100px;
  }

  /*Search Page*/
  #search-page-title {
    font-size: 30px;
    margin-left: 50px;
    margin-bottom: 30px;
  }

  #search-container {
    width: 100%;
    max-width: 340px;
    display: grid;
    gap: 5px;
  }

  #search-fields-container {
    display: grid;
    max-width: 340px;
  }

  #filter-label {
    width: 150px;
    font-size: 20px;
    margin-left: 0px;
  }

  #filter-select {
    margin-left: 120px;
    margin-top: -45px;
  }

  #search-field {
    font-size: 18px;
    max-width: 250px;
  }

  #search-icon-container {
    width: 100px;
  }

  #search-container input {
    width: 300px;
    height: 35px;
    border-radius: 10px;
  }

  #search-container img {
    cursor: pointer;
    border-radius: 10px;
    padding: 2px;
    background-color: rgba(87, 141, 16, 0.678);
  }

  #search-icon {
    margin-left: 250px;
    margin-top: -50px;
    margin-bottom: 15px;
  }

  #search-results-container {
    display: grid;
    max-width: 350px;
  }

  .search-recipe-container {
    width: 300px;
    height: 150px;
  }

  .search-recipe-container:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  .search-images-container {
    display: grid;
    place-content: center;
    width: 100px;
  }

  .search-result-image {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }

  .name-stats {
    margin-top: 10px;
    display: grid;
    justify-content: space-around;
  }

  .search-recipe-name {
    font-size: 18px;
  }

  .search-author-likes {
    display: flex;
    gap: 15px;
  }

  #search-loading {
    width: 100px;
    max-width: 350px;
  }

  #search-loading .lds-dual-ring {
    margin-left: 20px;
  }

  /*New Recipe Page*/

  #new-recipe-container {
    width: 100%;
    max-width: 400px;
  }

  #new-recipe-title {
    font-size: 35px;
    margin-left: 45px;
  }

  #new-recipe-form {
    width: 300px;
    padding: 20px;
  }

  .new-recipe-form-input {
    font-size: 20px;
    margin-top: 10px;
    width: fit-content;
  }

  #cooking-time-es {
    margin-left: 0px;
    margin-top: 10px;
    max-width: 100px;
  }

  #new-recipe-name {
    height: 35px;
    width: 250px;
  }

  #category-time-container {
    display: grid;
    width: fit-content;
  }

  #category-container {
    display: flex;
    gap: 10px;
  }

  #new-recipe-category {
    width: 130px;
    height: 30px;
    margin-left: 40px;
  }

  #categoria {
    margin-right: -20px;
  }

  #servings-container {
    display: flex;
    gap: 10px;
  }

  #cooking-time-container {
    display: flex;
    gap: 10px;
  }

  #cooking-time {
    width: 130px;
  }

  #cooking-time-input-es {
    margin-left: 70px;
    width: 130px;
    margin-top: 20px;
  }

  #porciones {
    margin-left: 0px;
    margin-right: -20px;
  }

  #servings {
    width: 130px;
    margin-left: 40px;
  }

  #new-ingredients-container {
    max-width: 250px;
  }

  .ingredient-container {
    margin-left: 0px;
    width: 200px;
    max-width: 200px;
    gap: 3px;
    display: grid;
  }

  .new-recipe-ingredient,
  .quantity,
  .ingredient-unit {
    border-radius: 5px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .exclude-ingredient {
    width: 30px;
    height: 30px;
    margin-top: -3px;
    margin-bottom: -5px;
    margin-right: -30px;
    font-size: 25px;
    cursor: pointer;
    color: rgba(255, 0, 0, 0.671);
  }

  .new-recipe-ingredient {
    margin-left: 0px;
    width: 250px;
    height: 30px;
    font-size: 15px;
  }

  .ingredient-container label {
    margin-left: 85px;
    margin-top: -25px;
  }

  .quantity {
    width: 80px;
    height: 30px;
    margin-left: 0px;
  }

  .ingredient-unit {
    width: 100px;
    height: 30px;
    margin-left: 135px;
    margin-top: -35px;
    margin-bottom: 10px;
  }

  #add-ingredient {
    width: 150px;
    height: 30px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    background-color: rgba(9, 30, 224, 0.39);
  }

  #new-recipe-preparation-style {
    width: 150px;
    height: 30px;
    border-radius: 5px;
    font-family: 'Patua One', cursive;
    font-size: 18px;
  }

  #step-by-step-container {
    display: grid;
    gap: 30px;
    width: fit-content;
    max-width: 280px;
  }

  .step-container {
    width: 280px;
    display: grid;
    gap: 5px;
  }

  .step-container label {
    width: 100px;
    font-size: 20px;
  }

  .step {
    width: 250px;
  }

  .exclude-step {
    width: 30px;
    height: 30px;
    margin-top: 0px;
    margin-bottom: -5px;
    margin-right: -30px;
    font-size: 25px;
    cursor: pointer;
    color: rgba(255, 0, 0, 0.671);
  }

  #add-step {
    width: 150px;
    height: 30px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    background-color: rgba(9, 31, 224, 0.39);
  }

  #hidden {
    font-weight: 100;
    color: rgb(94, 99, 104);
  }

  #new-recipe-ingredients {
    height: 200px;
    border-radius: 5px;
    font-family: 'Patua One', cursive;
    font-size: 18px;
    resize: none;
  }

  #new-recipe-preparation {
    width: 260px;
    height: 300px;
    border-radius: 5px;
    font-family: 'Patua One', cursive;
    font-size: 18px;
    resize: none;
  }

  #pictures-container {
    width: 100%;
    display: flex;
  }

  #pictures-input {
    display: grid;
    gap: 20px;
  }

  #pictures-preview {
    display: flex;
    gap: 10px;
    margin-left: 10px;
  }

  #new-recipe-pictures {
    width: 200px;
    margin-bottom: 50px;
  }

  .image-thumbnail {
    width: 80px;
    height: 150px;
  }

  .image-thumbnail img {
    width: 80px;
    height: 100px;
    z-index: -1;
  }

  .image-thumbnail p {
    color: red;
    margin-top: -25px;
    font-size: 25px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 900;
    text-align: right;
    padding-right: 10px;
    cursor: pointer;
    filter: opacity(80%);
    background-color: rgb(255, 255, 255);
  }

  #new-recipe-buttons {
    margin-left: -5px;
    gap: 15px;
  }

  #edit-recipe-buttons {
    display: flex;
    gap: 15px;
    margin-left: -10px;
  }

  #new-recipe-submit {
    width: 130px;
    height: 45px;
    font-size: 18px;
    margin-bottom: -20px;
    margin-left: -230px;
  }

  #new-recipe-submit:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #post-recipe-submit {
    width: 130px;
    height: 45px;
    font-size: 18px;
    margin-bottom: 30px;
    margin-left: 0px;
  }

  #post-recipe-submit:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #new-recipe-cancel {
    width: 130px;
    height: 45px;
    font-size: 18px;
    padding-left: 35px;
    margin-top: -50px;
  }

  #new-recipe-cancel-es {
    width: 130px;
    height: 45px;
    font-size: 18px;
    padding-left: 30px;
    margin-top: -50px;
  }

  #post-recipe-cancel {
    width: 130px;
    height: 45px;
    font-size: 18px;
    padding-left: 35px;
    margin-top: 0px;
  }

  #post-recipe-cancel-es {
    width: 130px;
    height: 45px;
    font-size: 18px;
    padding-left: 30px;
    margin-top: 0px;
  }

  #new-recipe-cancel:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #new-recipe-delete {
    padding-top: 10px;
    padding-left: 15px;
    width: 140px;
    height: 45px;
    font-size: 18px;
    margin-top: -50px;
  }

  #new-recipe-delete-es {
    padding-top: 10px;
    padding-left: 5px;
    width: 140px;
    height: 45px;
    font-size: 18px;
    margin-top: -50px;
  }

  #new-recipe-delete:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #missing-recipe-data {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.363);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: grid;
    justify-content: center;
    place-content: center;
  }

  #missing-data-container {
    padding: 50px;
    border-radius: 10px;
    background-color: rgba(219, 207, 207, 0.973);
    box-shadow: 55px 55px 55px rgba(8, 6, 27, 0.616);
    animation: confirm-delete 0.15s linear 1;
    text-align: center;
    z-index: 2;
  }

  #missing-data-container button {
    background-color: rgba(5, 5, 212, 0.63);
  }

  #missing-message {
    font-size: 25px;
    margin-bottom: 30px;
    margin-top: -20px;
  }

  #ok-missing {
    width: 150px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 25px;
  }

  /*Dashboard*/

  #dash-container {
    padding-left: 20px;
  }

  #dash-title {
    font-size: 30px;
    margin-left: 40px;
  }

  #dash-activity {
    width: fit-content;
    font-size: 20px;
    margin-left: 0px;
  }

  .activity-container {
    max-width: 300px;
  }

  #activity-items-container {
    width: 300px;
    padding: 20px 30px 20px 40px;
  }

  #all-activity-option:hover {
    transform: scale(1.05);
    transition: 0.1s;
  }

  .activity-item {
    font-size: 18px;
  }

  .dash-option {
    font-size: 22px;
    width: 280px;
    cursor: pointer;
    margin-left: 0px;
  }

  .dash-option:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #dash-collections {
    margin-bottom: 50px;
  }

  /*Favorites Page*/
  #favorites-container {
    width: 300px;
    margin-top: 50px;
    padding: 20px;
  }

  #favorites-title {
    font-size: 27px;
    margin-left: 0px;
    width: 280px;
  }

  .favorite-item-container {
    margin-bottom: -40px;
  }

  .favorite-item-container img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .favorite-item-container:hover {
    transform: scale(1.03);
    transition: 0.2s;
  }

  .favorite-item {
    font-size: 20px;
    margin-top: 0px;
  }

  /*My Recipes Page*/
  #myrecipes-container {
    width: 300px;
    margin-top: 50px;
    padding: 20px;
  }

  #myrecipes-title {
    font-size: 27px;
    margin-left: 0px;
    width: 280px;
  }

  .myrecipe-item-container {
    display: flex;
    height: 150px;
    transition: 0.2s;
    margin-bottom: -40px;
  }

  .myrecipe-item-container img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .myrecipe-item-container:hover {
    transform: scale(1.03);
    transition: 0.2s;
  }

  .myrecipe-item {
    font-size: 20px;
    margin-top: 0px;
  }

  /*Account Page*/

  @keyframes before-update {
    0% {
      transform: translateX(90vw);
    }

    100% {
      transform: translateX(5vw);
    }
  }

  @keyframes after-update {
    0% {
      transform: translateX(5vw);
    }

    100% {
      transform: translateX(90vw);
    }
  }

  #edit-user-container {
    padding-left: 0px;
    place-content: center;
  }

  #account-container {
    width: 300px;
    display: grid;
    margin-left: 0px;
    padding-left: 20px;
  }

  #edit-user-title {
    font-size: 30px;
  }

  .account-option {
    font-size: 20px;
    width: 270px;
  }

  .account-option:hover {
    transform: scale(1.05);
    transition: 0.2s;
  }

  #delete-page {
    margin-bottom: 30px;
  }

  #delete-page:hover {
    transform: scale(0.95);
    transition: 0.2s;
  }

  .update-field-container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.582);
    position: fixed;
    top: 0px;
    left: 0px;
  }

  .account-content-container {
    width: 90vw;
    margin-left: 10vw;
    height: 100vh;
    padding-left: 30px;
    max-width: 340px;
  }

  #account-image-prompt {
    font-size: 20px;
  }

  #account-avatars-imgs-container {
    max-width: 340px;
  }

  .account-signup-avatar {
    width: 70px;
    height: 70px;
  }

  #account-image {
    width: 250px;
    margin-bottom: 70px;
    margin-top: -30px;
  }

  #account-current-image {
    width: 120px;
    height: 120px;
  }

  .account-image-input {
    font-size: 20px;
  }

  #new-account-image {
    width: 250px;
    height: 75px;
    margin-bottom: 300px;
  }

  #account-pictures-preview {
    width: 250px;
    height: 200px;
    margin-left: 0px;
    margin-top: -100px;
    margin-bottom: -60px;
  }

  .account-image-thumbnail img {
    width: 100px;
    height: 120px;
  }

  .account-X-container {
    width: 100px;
    margin-top: -50px;
    font-size: 25px;
    filter: opacity(60%);
    background-color: rgb(255, 255, 255);
  }

  .account-buttons-container {
    width: 250px;
    gap: 10px;
  }

  .account-buttons-container button {
    width: 150px;
    font-size: 15px;
  }

  #account-current-country p {
    font-size: 20px;
  }

  #account-country-prompt {
    font-size: 20px;
    margin-right: 20px;
  }

  #account-country {
    margin-bottom: 70px;
    margin-top: 20px;
    width: 150px;
    height: 30px;
  }

  #account-about {
    width: 250px;
    height: 250px;
  }

  #account-password-container {
    width: 250px;
  }

  .account-password-label {
    font-size: 20px;
  }

  #account-enter-password,
  #account-password-confirm {
    display: grid;
  }

  #account-password,
  #account-confirm-password {
    margin-left: auto;
    margin-top: 20px;
  }

  #account-delete-prompt {
    font-size: 20px;
  }

  #account-confirm-delete {
    width: 300px;
    height: 200px;
    padding: 20px;
    padding-top: 40px;
  }

  #account-confirm-delete p {
    font-size: 20px;
  }

  #account-confirm-delete-buttons {
    margin-left: 0px;
  }

  #account-confirm-delete-buttons button {
    width: 120px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
  }

  /*Collections Page*/

  #collections-page-title {
    font-size: 30px;
  }

  #user-collections-container {
    margin-top: 50px;
    width: 300px;
    height: 400px;
    padding: 0px;
    gap: 10px;
    overflow-y: auto;
    justify-content: start;
  }

  #user-collections-container::-webkit-scrollbar {
    display: none;
  }

  .user-collection-container {
    width: 300px;
    height: 350px;
    padding: 10px;
    padding-bottom: 70px;
    gap: 10px;
  }

  .user-collection-container:hover {
    transform: scale(1.02);
    transition: 0.2s;
  }

  .user-collection-title {
    font-size: 20px;
    max-width: 200px;
    line-break: anywhere;
    max-height: 60px;
    overflow: auto;
  }

  #collection-edit-title {
    width: 25px;
    height: 25px;
    margin-top: 30px;
    margin-left: 5px;
    opacity: 75%;
    z-index: 10;
    position: absolute;
    right: -7px;
    bottom: 30px;
  }

  #collection-edit-image {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 10px;
    top: 5px;
    opacity: 75%;
    z-index: 10;
  }

  .user-collection-image {
    width: 150px;
  }

  #new-collection-button {
    width: 250px;
    font-size: 20px;
    margin-top: 30px;
  }

  #new-collection-button:hover {
    transform: scale(1.02);
    transition: 0.2s;
  }

  #container-collection {
    margin-top: 100px;
    width: 300px;
    height: 600px;
  }

  #container-collection-title {
    width: 300px;
    font-size: 22px;
    margin-left: 70px;
    margin-top: 40px;
  }

  #collection-recipes-container {
    width: 250px;
    height: 400px;
    padding-bottom: 20px;
  }

  #collection-recipes-container::-webkit-scrollbar {
    display: none;
  }

  #options-container-collection {
    margin-left: 220px;
  }

  #edit-collection-option {
    margin-left: 70px;
    margin-top: auto;
    margin-bottom: -570px;
  }

  #close-container-collection {
    font-size: 20px;
  }

  #save-container-collection {
    font-size: 20px;
  }

  .collection-recipe {
    width: 250px;
    height: 100px;
    padding: 10px;
    gap: 10px;
  }

  .collection-recipe h5 {
    font-size: 18px;
    width: 170px;
  }

  .collection-recipe-image {
    width: 60px;
    height: 60px;
  }

  .collection-recipe-name-view p {
    position: absolute;
    font-size: 15px;
    width: 100px;
    /* margin-top: -10px; */
    margin-left: -70px;
    bottom: -20px;
  }

  .collection-recipe-remove {
    margin-right: 0px;
    margin-left: -118px;
    margin-top: 65px;
    font-size: 15px;
    height: 30px;
    width: fit-content;
    z-index: 2;
  }

  .collection-recipe-remove-es {
    margin-right: 0px;
    margin-left: -130px;
    margin-top: 65px;
    font-size: 15px;
    height: 30px;
    width: fit-content;
    z-index: 2;
  }

  #collection-recipes-confirm-delete {
    width: 250px;
    height: 200px;
    padding-top: 45px;
    padding-left: 30px;
  }

  #collection-recipes-confirm-delete p {
    font-size: 20px;
  }

  #collection-confirm-delete-buttons button {
    font-size: 20px;
  }

  #collection-confirm-delete-buttons-es button {
    font-size: 20px;
  }

  #collection-cancel-delete {
    background-color: gray;
  }

  #collection-do-delete {
    background-color: rgba(5, 5, 212, 0.63);
  }

  #new-collection-div {
    width: 300px;
    height: 500px;
  }

  #new-collection-form {
    height: 450px;
    padding-left: 20px;
  }

  .new-collection-form-input {
    font-size: 20px;
  }

  #image-options {
    width: 300px;
    height: 300px;
    margin-left: 0px;
  }

  .collection-image {
    width: 100px;
    height: 100px;
  }

  #new-collection-name {
    height: 30px;
    width: 250px;
    margin-top: -60px;
  }

  #new-collection-buttons {
    margin-left: 0px;
  }

  #new-collection-submit {
    width: 120px;
    height: 35px;
    font-size: 20px;
  }

  #new-collection-cancel {
    width: 120px;
    height: 35px;
  }

  #edit-collection-div {
    width: 300px;
  }

  #edit-collection-name {
    width: 250px;
  }

  #edit-image-container {
    width: 250px;
  }

  .edit-image-container {
    width: 400px;
    height: 300px;
  }

  #collection-image-options {
    width: 300px;
    height: 250px;
    margin-right: 0px;
    margin-left: -60px;
  }

  .collection-image-option:hover {
    transform: scale(1.06);
    transition: 0.2s;
  }

  #save-cancel-buttons {
    display: flex;
    gap: 60px;
    margin-bottom: 10px;
  }

  #edit-collection-cancel {
    margin-right: -20px;
  }

  #edit-collection-cancel {
    background-color: gray;
  }

  #edit-collection-save {
    background-color: rgb(93, 93, 210);
  }

  #edit-collection-delete {
    background-color: rgba(233, 48, 23, 0.512);
    border-radius: 5px;
    width: 175px;
    height: 30px;
    color: rgb(61, 61, 61);
    place-self: center;
    cursor: pointer;
  }

  #collection-confirm-delete {
    width: 300px;
    height: 200px;
    padding: 20px;
    padding-top: 40px;
  }

  #collection-confirm-delete p {
    font-size: 20px;
  }

  #collection-delete-buttons {
    margin-left: 10px;
  }

  #collection-delete-buttons button {
    width: 100px;
    font-size: 20px;
  }

  .collection-cancel-delete {
    background-color: gray;
  }

  .collection-do-delete {
    background-color: rgba(5, 5, 212, 0.63);
  }

  #display-limit-container {
    width: 100%;
    height: 100%;
    position: fixed;
    place-content: center;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.363);
  }

  #display-limit {
    width: 300px;
    padding-top: 0px;
  }

  /*User Page*/

  #user-info-container {
    width: 300px;
    padding: 20px;
  }

  #name-image-flag {
    display: grid;
    width: 300px;
    height: fit-content;
    gap: 0px;
    margin-bottom: 20px;
  }

  #user-page-image {
    width: 120px;
  }

  #name-image-flag h1 {
    font-size: 25px;
    margin-top: 0px;
  }

  #country-flag {
    width: 50px;
    height: 20px;
    margin-left: 180px;
    margin-top: -40px;
  }

  #about-user {
    width: 300px;
    height: 250px;
  }

  #about-user p {
    max-width: 250px;
  }

  #about-title {
    font-size: 20px;
  }

  #user-page-recipes {
    width: 280px;
    height: fit-content;
  }

  #user-page-recipes-label {
    font-size: 20px;
  }

  #user-page-recipes a {
    font-size: 18px;
  }

  /*Activity Page*/

  #opt-out-option {
    display: flex;
    margin-left: 30px;
    margin-top: -10px;
  }

  #opt-out-option p {
    margin-top: 15px;
    margin-left: 10px;
  }

  /*404 page*/
  #missing-page-container {
    padding-top: 50%;
  }

  #chef-sleep {
    width: 200px;
    height: 350px;
    margin-top: -100px;
    place-self: center;
  }

  /*Missing user*/
  #missing-user-container {
    padding-top: 50%;
  }

  #missing-user {
    width: 200px;
    height: 200px;
    margin-top: -100px;
    place-self: center;
  }
}